import moment from 'moment'
import { isEmpty } from 'lodash'
const apiRestUrl = process.env.REACT_APP_API_REST_URL
const apiDireccionesUrl = process.env.REACT_APP_API_DIRECCIONES_URL
const apiLogisticaUrl = process.env.REACT_APP_API_LOGISTICA_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

//const token = localStorage.getItem('token');

export const getSectorizacionByFilters = async (values) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiRestUrl}/pedido`

	const raw = {
		"estado": isEmpty(values) ? [4,5] : values.estado,
		"fechaTomaInicio": isEmpty(values) ? moment(new Date()).format("DDMMYYYY") : moment(values.fechaTomaInicio, 'DD-MM-YYYY').format("DDMMYYYY"),
		"fechaTomaFin": isEmpty(values) ? moment(new Date()).format("DDMMYYYY") : moment(values.fechaTomaFin, 'DD-MM-YYYY').format("DDMMYYYY"),
		"c_comuna": isEmpty(values) ? null : values.c_comuna,
		"camion": isEmpty(values) ? null : values.camion,
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			//body: raw
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsTransmision,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los pedidos`
		}
	}
}

const columnsTransmision = [
	{ field: 'pedidoid', headerName: 'Pedido', width: 130 },
	{ field: 'estado', headerName: 'Estado', width: 140 },
	{ field: 'fechatoma', headerName: 'Fecha y hora', width: 180 },
	{ field: 'nombrecliente', headerName: 'Nombre Cliente', width: 180 },
	{ field: 'telefono', headerName: 'Télefono', width: 140 },
	{ field: 'comunaid', headerName: 'ComID', width: 140 },
	{ field: 'comuna', headerName: 'Comuna', width: 140 },
	{ field: 'calle', headerName: 'Calle', width: 200 },
	{ field: 'numero', headerName: 'Número', width: 140 },
	{ field: 'depto', headerName: 'Depto', width: 120 },
	{ field: 'casa', headerName: 'Casa', width: 120 },
	{ field: 'block', headerName: 'Block', width: 120 },
	{ field: 'referencia', headerName: 'Referencia', width: 200 },
	{ field: 'cantprod', headerName: 'Detalle', width: 600 },
	{ field: 'camion', headerName: 'Camion', width: 140 },
	{ field: 'censal', headerName: 'Censal', width: 140 },
	{ field: 'observacion', headerName: 'Observacion', width: 400 },
	{ field: 'usuario', headerName: 'Usuario', width: 140 }
]

export const getSectoresComuna = async (c_comuna, sector) => {
	const url = `${apiLogisticaUrl}/censal/sectoresComuna`

	const raw = {
		"comuna": c_comuna,
		"filter": isEmpty(sector) ? '' : sector,
		"cantidad": 6
	};

	try {
		const resp = await fetch(url, {
			//method: 'get', 
			//headers: myHeaders, 
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})
		if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
			//const { calles } = await resp.json()
			const data = await resp.json()

			return {
				//calles,
				sectores: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las calles`
		}
	}
}

export const updatePedido = async (valueSector) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiRestUrl}/pedido/update`

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(valueSector),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const respuesta = await resp.json()

			if (respuesta.cod_error === 9000) {
				return {
					message: 'Pedido editado exitosamente',
					status: 'OK'
				}
			} else {
				return {
					message: 'No se pudo editar el pedido',
					status: 'NOK'
				}
			}

		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {

		console.log(err)
		return {
			status: 'NOK',
			message: `No se pudo conectar para actualizar el pedido`
		}
	}
}


export const censadoAutomatico = async (valueSector) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiDireccionesUrl}/sector`

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(valueSector),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const { data, respuesta } = await resp.json()

			return {
				sector: data,
				status: respuesta.mensaje_sis
			}
		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para actualizar el pedido`
		}
	}
}