import moment from 'moment'
import { isEmpty } from 'lodash'
//const apiUrl = process.env.REACT_APP_API_URL
const apiRestUrl = process.env.REACT_APP_API_REST_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

const token = localStorage.getItem('token');

export const addPedido = async(values) => {
	//const url = `${ apiUrl }/pedido`
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiRestUrl}/pedido/add`
  
    try {
        const resp = await fetch( url, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(values),
            redirect: 'follow'
		})
        
        if(resp.status === 200 || resp.status === 201 || resp.status === 204) {
            const { cod_error } = await resp.json()

			if (cod_error === 9000) {
				return {
					message: 'Pedido creado exitosamente',
					status: 'OK'
				}
			} else {
				return {
					message: 'No se pudo crear el pedido',
					status: 'NOK'
				}
			}

		} else {
            return {
				status: 'NOK',
			    message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para crear el pedido`
		}
	}
}

export const getPedidosByFilters = async (values) => {
	const url = `${apiRestUrl}/pedido/pedidoDetalle`

	const raw = {
		"estado": isEmpty(values) ? 3 : values.estado,
		"fechaTomaInicio": isEmpty(values) ? moment(new Date()).format("DDMMYYYY") : moment(values.fechaTomaInicio, 'DD-MM-YYYY').format("DDMMYYYY"),
		"fechaTomaFin": isEmpty(values) ? moment(new Date()).format("DDMMYYYY") : moment(values.fechaTomaFin, 'DD-MM-YYYY').format("DDMMYYYY"),
		"c_comuna": isEmpty(values) ? null : values.c_comuna,
		"camion": isEmpty(values) ? null : values.camion,
		"clse_sector": isEmpty(values) ? null : values.clse_sector
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			//body: raw
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsPedidos,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los pedidos`
		}
	}
}

const columnsPedidos = [
	{ field: 'pedidoid', headerName: 'Número Pedido', width: 180 },
	{ field: 'usuario', headerName: 'Usuario', width: 180 },
	{ field: 'nombrecliente', headerName: 'Nombre Cliente', width: 250 },
	{ field: 'telefono', headerName: 'Télefono', width: 200 },
	{ field: 'comunaid', headerName: 'Comuna ID', width: 280 },
	{ field: 'comuna', headerName: 'Comuna', width: 280 },
	{ field: 'calle', headerName: 'Calle', width: 300 },
	{ field: 'numero', headerName: 'Número', width: 150},
	{ field: 'block', headerName: 'Block', width: 150},
	{ field: 'casa', headerName: 'Casa', width: 150},
	{ field: 'depto', headerName: 'Depto', width: 150},
	{ field: 'referencia', headerName: 'Referencia', width: 150},
	{ field: 'observacion', headerName: 'Observación', width: 200},
	{ field: 'estado', headerName: 'Estado', width: 150 },
	{ field: 'fechatoma', headerName: 'Fecha de Toma', width: 200},
	{ field: 'producto', headerName: 'Producto', type: 'number', width: 150 },
	{ field: 'cantidad', headerName: 'Cantidad', type: 'number', width: 150 },
	{ field: 'precio', headerName: 'Precio Unitario', type: 'number', width: 200 },
	{ field: 'montodescuento', headerName: 'Descuento Unitario', type: 'number', width: 200 }
]

export const getAnotacionesPedido = async(pedido) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiRestUrl}/anotaciones`

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			//body: raw
			body: JSON.stringify({ "pedido": pedido }),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()

			return {
				anotaciones: data.data,
				//columns: columnsPedidos,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las anotaciones`
		}
	}
}

/*
export const getPedidoById = async(id) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	const url = `${apiRestUrl}/pedido/id`

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify({ "id": id }),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()

			return {
				pedido: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las anotaciones`
		}
	}
}
*/