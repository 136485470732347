import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableBase } from '../descuentos/TableBase'
import { FormBase } from '../descuentos/FormBase'
import { getProductosDescuentos } from '../../helpers/producto/apiCalls'
import Grid from '@material-ui/core/Grid'
import Spinner from '../base/Spinner'
import { GeneralContext } from '../../context/GeneralContext' 


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

export const DescuentosScreen = () => {
    const classes = useStyles()
    const [data, setData] = useState({})
    //const [dataToUpdate, setDataToUpdate] = useState([])
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext);
    const [reload, setReload] = useState('')
    const [openSpinner, setOpenSpinner] = useState(false)
    
    useEffect(() => {
        let isMounted = true
        const fetchData =  async () => {
            setOpenSpinner(true)
            getProductosDescuentos()
                .then(( result ) => {
                    setData(result)
                    setOpenSpinner(false)
                })
        }
        fetchData()
        return () => { isMounted = false }
    }, [reload])

    return (
        <div className={classes.root}>
            <Grid 
                container  
                justify="center"
                spacing={1}
                style={{ 
                    backgroundColor: 'white',
                    paddingLeft: 10,
                }}
                >
                { data.columns &&
                    <Grid item xs={8} justify="center" alignItems="center">
                        <TableBase 
                            getData={ data }
                            setStateGeneral = { setStateGeneral }
                            stateGeneral = { stateGeneral }

                        />
                    </Grid>
                }
                <Grid item xs={4}>
                    <FormBase 
                        dataToUpdate = { [] } 
                    />
                </Grid>  
            </Grid>
            <Spinner open={ openSpinner }/>
        </div>
    )
}