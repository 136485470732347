export const styles = {
    fontTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 700 //bold
    },
    fontMessage: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 400, //normal
        fontSize: 13,
    },
    popUpButtonCancelar: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 10, //normal
        fontWeight: 700,
        width: 80,
        height: 30,
        color: '#FF624E',
        backgroundColor: '#FF624E'
    },
    popUpButtonAceptar: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 10, //normal
        fontWeight: 700,
        backgroundColor: '#1A53BA',
        color: 'white',
        width: 120,
        height: 40
    },
    buttonExcel: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 10, //normal
        fontWeight: 700,
        backgroundColor: '#36B37E',
        color: 'white',
        width: 40,
        height: 30,
        borderRadius: 10

    },
    popUpButtonLimpiar: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 10, //normal
        fontWeight: 700,
        backgroundColor: '#FF624E',
        color: 'white',
        width: 70,
        height: 40
    },
    cleanFilters: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 10, //normal
        fontWeight: 700,
        backgroundColor: '#FF624E',
        color: 'white',
        borderRadius: 5,
        width: 70,
        height: 40
    },
    popUpButtonDisabled: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 10, //normal
        fontWeight: 700,
        backgroundColor: '#d1cfcb',
        color: 'white',
        width: 120,
        height: 40
    },
}