import React, { useContext, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { DrawerBase } from '../components/base/DrawerBase'
import { UserContext } from '../context/UserContext'
import rutas from '../helpers/rutas'
import { obtenerClaims } from '../helpers/util'
import { isEmpty } from 'lodash'
import Login from '../components/base/Login'


export const HomeRoutes = () => {
    const { userContextState, setUserContextState } = useContext(UserContext);
    const { claims } = userContextState
    //const perfil = !isEmpty(claims[1]) ? claims[1].valor : ""

    useEffect(claims => {
        if(isEmpty(claims)) {
            setUserContextState({ ...userContextState, claims: obtenerClaims() })
        }
    }, [])

    return (
        <div className="page-container">
            <div className="content-wrap">  
            <Switch>
            {
            rutas.map(ruta =>
                <Route 
                    key={ruta.path} 
                    path={ruta.path}
                    exact={ruta.exact}
                >
                    { ruta.path === "/login" &&
                        <ruta.componente />
                    }

                    { ((ruta.path !== "/login" && isEmpty(claims[0])) || (ruta.path === "" && !isEmpty(claims[0])) || (ruta.path === "" && isEmpty(claims[0]))) &&
                         <Login />
                    }

                    { ruta.path !== "/login" && !isEmpty(claims[0]) && claims[0].permisos.includes(ruta.permiso) && //!isEmpty(claims[1]) -- (perfil === ruta.isAdmin || perfil === "administrador")
                            <DrawerBase content={ <ruta.componente />} permisoRuta={ruta.permiso} permisosUser={claims[0].permisos} //permiso={claims[0].permisos.includes(ruta.permiso) ? true : false
                            />
                    }
                    { ruta.path !== "/login" && ruta.path !== "" && !isEmpty(claims[0]) && !claims[0].permisos.includes(ruta.permiso) && //&& (perfil !== "administrador" && perfil !== ruta.isAdmin)
                            "Perfil No Autorizado"
                    }
                </Route>
                )
            }
          </Switch>
            </div>
        </div>
    )
}
