//const apiUrl = process.env.REACT_APP_API_URL
const apiAuthUrl = process.env.REACT_APP_API_AUTH_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')


export const login = async(values) => {
	//const url = `${ apiUrl }/login`
	const url = `${ apiAuthUrl }/login`

	const body = {
		usuario: values.usuario,
		password: values.password
	}
  
    try {
        const resp = await fetch( url, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(body),
            redirect: 'follow'
		})
        
		if(resp.status === 200 || resp.status === 201) {
            const { usuario, nombre, token, expiracion, permisos }  = await resp.json()

			return {
				usuario,
			    nombre,
				token,
				expiracion,
				permisos,
				status: 'OK'
			}
		} else {
            return {
				status: 'NOK',
			    message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar crear el pedido`
		}
	}
}