import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TableBase } from '../toma-pedidos/TableBase';
import { FormBase } from '../toma-pedidos/FormBase'
import { getProductosPreciosByComuna } from '../../helpers/producto/apiCalls'
import { GeneralContext } from '../../context/GeneralContext' 
import Grid from '@material-ui/core/Grid';
import Spinner from '../base/Spinner';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

export const TomaPedidosScreen = () => {
    const classes = useStyles();
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext);
    const [data, setData] = useState({})
    const [reload, setReload] = useState('')
    const [openSpinner, setOpenSpinner] = useState(false)
    
    useEffect(() => {
        let isMounted = true
        const fetchData =  async () => {
            setOpenSpinner(true)
            getProductosPreciosByComuna(stateGeneral.comunaSll)
                .then(( result ) => {
                    setData(result)
                    setStateGeneral({ 
                        ...stateGeneral, 
                        pedido: result.rows, 
                        totales: {
                            subTotal: 0,
                            descuento: 0,
                            total: 0
                    } })
                    setOpenSpinner(false)
                })
        }
        fetchData()
        return () => { isMounted = false }
    }, [reload])

    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormBase 
                        setReload = { setReload }
                        reload = { reload }
                    />
                </Grid>  
                { stateGeneral.pedido && data.columns &&
                    <Grid item xs={12}>
                        <TableBase 
                            getData={ data }
                            data={ data.rows } 
                        />
                    </Grid>
                }
            </Grid>
            <Spinner open={ openSpinner }/>
        </div>
    )
}