import React, { Fragment } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
  } from "react-router-dom";
import { HomeRoutes } from './HomeRoutes';
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { esES } from '@material-ui/core/locale';
import { UserProvider } from '../context/UserContext'

const theme = createTheme({
    palette: {
      primary: { main: '#1A53BA' },
    },
    typography: {
        fontFamily: '"Poppins", sans-serif',
    },
  }, esES)

  
export const AppRouter = () => {
    return (
        <Router>
            <UserProvider>
                <ThemeProvider theme={theme}>
                    <Switch>
                        <Fragment>
                            {/* <Route path='/logout' component={ LoginScreen }></Route>     */}
                            <Route path='/' component={ HomeRoutes }></Route>    
                            {/* <Route to='/login' component={ Login }/> */}
                        </Fragment>
                    </Switch>
                </ThemeProvider>
            </UserProvider>
        </Router>
    )
}
