import React, { useState, useContext } from 'react'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
import { updateDescuentos } from '../../helpers/producto/apiCalls'
import { DialogAlert } from '../base/DialogAlert'
import Grid from '@material-ui/core/Grid';
import Spinner from '../base/Spinner'
import { GeneralContext } from '../../context/GeneralContext' 

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));


export const FormBase = () => {
    const classes = useStyles();
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext);
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)

    const handleCloseAlert = () => {
        if(openAlert) { 
            setOpenAlert(false)
        }     
    }

    const onHandleSubmit = e => {
        const dataToUpdate = stateGeneral.descuentos
        e.preventDefault()
        if(dataToUpdate.length === 0) {
            setMessage("No existen descuentos para actualizar...")
            setCodeAlert(false)
            setOpenAlert(true)
            return
        }

        setOpenSpinner(true)
        updateDescuentos(dataToUpdate)
            .then(({ message, status }) => {
                setMessage(message === "" ? "Error al actualizar precios" : message)
                setCodeAlert(status === "OK")
                setOpenAlert(true)
                setOpenSpinner(false)
            })
    }

    return (
       
            <div className={classes.root}>
                <form 
                    onSubmit={ onHandleSubmit } 
                >
                <Grid 
                    container 
                    spacing={3} 
                > 
                    <Grid item xs={3} container spacing={1} direction="row" style={{ alignItems: 'flex-end', flexDirection: 'row-reverse' }}>
                        <Grid item style={{ marginTop: 8 }}>
                            <ButtonControl
                                label="Actualizar Precios"
                                type="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                </form> 
                
                { openAlert  &&
                    <DialogAlert 
                        title="Alerta"
                        message= { message }
                        type="alert"
                        code={ codeAlert }
                        method="DELETE"
                        openAlert={ openAlert }
                        handleCloseAlert={ handleCloseAlert }
                    />
                }
                <Spinner open={ openSpinner }/>
            </div>
    )
}