import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DataGridNoRows from '../../common/DataGridNoRows'
import Grid from '@material-ui/core/Grid';
import { DataGrid } from '@material-ui/data-grid'
import { GeneralContext } from '../../context/GeneralContext' 
import { Summary } from '../../components/toma-pedidos/Summary'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .super-app-theme--header': {
            backgroundColor: '#FCDB00',
            fontSize: 13,
            fontWeight: 700
          },
         '& .MuiDataGrid-columnSeparator': {
            backgroundColor: '#FCDB00',
         } 
      },
      headerStyle: {
          height: 330,
      }
}));

export const TableBase = ({ getData, data=[] }) => {
    const classes = useStyles();
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext);
    const { columns } = getData
    
    const handleEditCellChange = ({ id, field, props }) => {
        const updatedData = data.map((element, index, arr) => {
            let returnValue = { ...element }
            if(id === element.id) {
                if(field === 'cantidad') {
                    if(props.value < 0 ) {
                        props.value = 0
                    } else if(props.value > 5) {
                        props.value = 5
                    } 
                    element.cantidad = props.value
                    
                    if(element.cantidad == 0) {
                        element.cantidadGarantia = 0
                        element.total = 0
                        return element
                    }        
                } else if (field === 'cantidadGarantia') {
                    if(props.value < 0 ) {
                        props.value = 0
                    } else if(props.value > 5) {
                        props.value = 5
                    } 
                    element.cantidadGarantia = props.value        
                }
                element.total = element.cantidadGarantia > 0 
                                    ? ((element.cantidad * element.precio) + (element.cantidadGarantia * element.precioGarantia)) - element.descuento 
                                    : (element.cantidad * element.precio) - element.descuento 
                return element
            } 
            return returnValue
        })

        const totales = () => {
            let totalDesc = 0
            let totalAcum = 0
            
            updatedData.map(x => {
                if(x.total> 0) {
                    totalAcum   += parseFloat(x.total) + parseFloat(x.descuento)
                    totalDesc   += parseFloat(x.descuento*x.cantidad)
                }
            })

            return {
                subTotal: totalAcum,
                descuento: totalDesc,
                total: totalAcum - totalDesc
            }
        }
        setStateGeneral({ ...stateGeneral, pedido: updatedData, totales: totales() })
    }


    return (
        <div className={classes.root}>
            <Grid 
                container 
                spacing={3} 
                style={{ 
                    backgroundColor: 'white',
                    paddingLeft: 10,
                }}
            > 
                <Grid item xs={10} className={classes.headerStyle}>
                    <DataGrid  
                            rows={ stateGeneral.pedido }   
                            rowHeight={25}  
                            headerHeight={ 40 }
                            disableColumnMenu={true} 
                            columns={ columns } 
                            hideFooter= { true }
                            onEditCellPropsChange={ handleEditCellChange }
                            components={{
                                NoRowsOverlay: ()=>DataGridNoRows("comuna"),
                            }}
                        />
                </Grid>
                <Grid 
                    item xs={2} 
                    container 
                    spacing={0} 
                    direction="row" 
                    style={{ display: 'block' }}
                    >
                        <Summary/>
                        <TextareaAutosize
                            style={{ marginTop: 15, marginLeft: 1, width: 200 }} 
                            maxRows={ 10 }
                            minRows={ 7 }
                            value={ stateGeneral.observacion }
                            maxLength={ 60 }
                            placeholder="Observación"
                            onChange={ e => setStateGeneral({ ...stateGeneral, observacion: e.target.value }) }
                        />
                </Grid>
                
            </Grid>
        </div>
    )
}

