import React, { useState, useEffect, useContext } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
import { getTiposanotAllEmer } from '../../helpers/transmision-emergencias/apiCalls'
import { getCamionesAll } from '../../helpers/camion/apiCalls'
import { agregarAnotacion } from '../../helpers/transmision-emergencias/apiCalls'
import { GeneralContext } from '../../context/GeneralContext'
import { DialogAlert } from '../base/DialogAlert'
import Spinner from '../base/Spinner'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '50ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textFieldCam: {
        marginTop: 58,
        marginLeft: 20,
        minWidth: 260,
    },
    textFieldObs: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '50ch',
        marginLeft: 12,
    },
}));

export const ActionBase = ({ setReload, reload }) => {

    const classes = useStyles();

    const { stateGeneral, setStateGeneral } = useContext(GeneralContext)
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [tiposanot, setTiposanot] = useState([])
    const [tipoanot, setTipoanot] = useState(null)
    const [camiones, setCamiones] = useState([])
    const [camion, setCamion] = useState(null)
    const [observacion, setObservacion] = useState('')

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    useEffect(() => {
        let isMounted = true
        setOpenSpinner(true)
        getTiposanotAllEmer()
            .then(({ tiposanot }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setTiposanot(tiposanot)
                }
            })
        setOpenSpinner(true)
        getCamionesAll()
            .then(({ camiones }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setCamiones(camiones)
                }
            })
        setOpenSpinner(false)
        return () => { isMounted = false }
    }, [reload])

    const handleChangeForm = (name, event) => {
        if (name === 'camion') {
            setCamion(event);
        } else if (name === 'tiposanot') {
            setTipoanot(event);
        }
    }

    const cleanData = () => {
        setStateGeneral({ ...stateGeneral })
        setObservacion('')
        setCamiones([])
        setCamion(null)
        setTiposanot([])
        setTipoanot(null)
        setReload(new Date())
    }

    const onHandleSubmitAnotacion = e => {
        e.preventDefault()

        let emergencias = [];
        emergencias = localStorage.getItem('emergenciasList');
        let emergenciasLength = 0;

        let emergenciasArray = [];
        emergenciasArray = emergencias.split(",");

        if (emergenciasArray == '') {
            emergenciasLength = 0;
        } else {
            emergenciasLength = emergenciasArray.length;
        }

        if (tipoanot === null || emergenciasLength === 0) {
            setMessage("Debe seleccionar las emergencias y un camión para hacer la asignación...")
            setCodeAlert(false)
            setOpenAlert(true)
            return
        } else {
            setOpenSpinner(true)
            agregarAnotacion(tipoanot, observacion, emergencias, emergenciasLength)
                .then(({ message, status }) => {
                    setMessage(message === "" ? "Error al agregar anotación" : message)
                    setCodeAlert(status === "OK")
                    setOpenAlert(true)
                    //cleanData()
                    setOpenSpinner(false)

                    setTimeout(() => {
                        window.location.reload()
                    }, "1000");

                })
        }
    }

    function something(value) {
        setObservacion(JSON.stringify(value));
    }

    return (
        <div className={classes.root}>
            <Grid item xs={12} style={{ marginTop: 30, marginBottom: 30 }} container justifycontent="center">
                <Grid item xs={4} style={{ marginTop: 10 }}>
                    <form onSubmit={onHandleSubmitAnotacion} >
                        <Grid container justifycontent="center" spacing={4}>
                            <Grid item>
                                {tiposanot && tiposanot.length > 0 &&
                                    <Autocomplete
                                        disableClearable
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={tiposanot}
                                        getOptionLabel={(option) => `${option.tiposanot} - ${option.descripcion}`}
                                        defaultValue={null}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                handleChangeForm("tiposanot", newValue.tiposanot)
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Anotación" placeholder="Seleccione Anotación" />
                                        )}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <Grid container justifycontent="center" spacing={4}>
                            <Grid item>
                                <TextField
                                    id="observacion"
                                    label="observacion"
                                    type="text"
                                    className={classes.textFieldObs}
                                    onChange={(e) => {
                                        something(e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item style={{ marginTop: -25, marginLeft: 140 }}>
                                <ButtonControl
                                    label="Agregar"
                                    type="primary"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />
        </div>
    )
}