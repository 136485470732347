import React, { useState, useEffect, useContext } from 'react'
import { GeneralContext } from '../../context/GeneralContext'
import DataGridNoRows from '../../common/DataGridNoRows'
import { DataGrid } from '@material-ui/data-grid'
import { Radio, Modal, Box, Typography, Button } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { InputText } from '../base/controls/InputText'
import { ButtonControl } from '../base/ButonControl'

import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { getComunasAll } from '../../helpers/comuna/apiCalls'
import { getCallesCallejero } from '../../helpers/calle/apiCalls'
import { getSectoresComuna, updatePedido, censadoAutomatico } from '../../helpers/sectorizacion/apiCalls'
import { agregarAnotacion } from '../../helpers/transmision/apiCalls'

import Divider from '@material-ui/core/Divider';
import { DialogAlert } from '../base/DialogAlert';
import Spinner from '../base/Spinner';

import { isEmpty } from 'lodash'

export const TableBase = ({ getData, data = [] }) => {

    const { columns } = getData
    const [open, setOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(null)
    const [modalSectorizar, setModalSectorizar] = useState(0)

    const { stateGeneral, setStateGeneral } = useContext(GeneralContext)
    const [comuna, setComuna] = useState(null)
    const [comunas, setComunas] = useState([])

    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)

    const [calle, setCalle] = useState(null)
    const [numero, setNumero] = useState(null)
    const [depto, setDepto] = useState(null)
    const [block, setBlock] = useState(null)
    const [casa, setCasa] = useState(null)
    const [camion, setCamion] = useState(null)
    const [referencia, setReferencia] = useState(null)
    const [censal, setCensal] = useState({ sector: '' })

    const [options, setOptions] = useState([]);
    const [optionsSector, setOptionsSector] = useState([]);
    const loading = open && options.length === 0 || optionsSector === 0;

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    const columnOption = {
        field: "✓", headerName: "", width: 10, sortable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center',
        renderCell: (data) => (
            <Radio checked={data.row.pedidoid == isChecked} value={data.row.pedidoid} color='primary' size="small" />
        )
    }

    const columnResectorizar = {
        field: "↧", headerName: "", width: 10, sortable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center', description: 'Resectorizar',
        renderCell: (modal) => (
            <button onDoubleClick={openModalResectorizar} title="Resectorizar ¡Doble Click!">↧</button>
        )
    }

    const [values, setValues] = useState({
        c_comuna: 0,
        calle: '',
        numero: '',
        censal: { sector: '' },
        sector: ''
    })

    useEffect(() => {
        let isMounted = true
        setStateGeneral({ ...stateGeneral, filters: values, observacion: '' })
        setOpenSpinner(true)
        if (comunas.length === 0) {
            getComunasAll()
                .then(({ comunas }) => {
                    if (isMounted) {
                        setComunas(comunas)
                    }
                })
        }
        setOpenSpinner(false)
        return () => { isMounted = false }
    }, [])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const openModalResectorizar = () => {
        handleOpen()
    }

    const handleChangeForm = (name, event) => {
        if (name == 'c_comuna') {
            setValues({ ...values, [name]: event })
            setStateGeneral({ ...stateGeneral, comuna: event })
        } else if (name == 'calle') {
            setValues({ ...values, [name]: event })
            setStateGeneral({ ...stateGeneral, calle: event })
        } else if (name == 'sector') {
            setValues({ ...values, [name]: event })
            setStateGeneral({ ...stateGeneral, sector: event })
        } else {
            setValues({ ...values, [name]: event.target.value })
            setStateGeneral({ ...stateGeneral, filters: [values] })
        }
    }

    const onChangeHandle = async value => {
        const { calles } = await getCallesCallejero(comuna.c_comuna, value).then((data) => { return data })
        if (calles && calles.length > 0) {
            setOptions(calles)
        } else {
            setOptions([{ idCalle: 0, calle: value }])
        }
    };

    const useStyles = makeStyles({
        table: {
            minWidth: 600,
        },
    });

    const classes = useStyles();

    const allColumns = [columnOption, columnResectorizar].concat(columns)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const onChangeSector = async value => {
        const { sectores } = await getSectoresComuna(comuna.c_comuna, value).then((data) => { return data })

        if (sectores && sectores.length > 0) {
            setOptionsSector(sectores)
        } else {
            setOptionsSector([{ sector: '' }])
        }
    };

    const onHandleSubmit = e => {
        e.preventDefault()

        setOpenSpinner(true)
        updatePedido({
            pedidoId: modalSectorizar,
            comuna: comuna.c_comuna,
            calle: calle.calle,
            numero: numero,
            depto: depto,
            block: block,
            casa: casa,

            camion: camion,

            referencia: referencia,
            censal: censal.sector,
        })
            .then(({ message, status }) => {
                setMessage(message === "" ? "Error al actualizar el pedido" : message)
                setCodeAlert(status === "OK")
                setOpenAlert(true)
                setOpenSpinner(false)

                if (status === "OK") {
                    handleClose()
                    setTimeout(() => {
                        window.location.reload()
                    }, "1000");
                }
            })
    }

    const guardarYReconfirmar = e => {
        e.preventDefault()

        if (comuna.c_comuna === 0 || comuna.c_comuna === null) {
            setMessage("Debe seleccionar una comuna para reconfirmar el pedido...")
            setCodeAlert(false)
            setOpenAlert(true)
            return
        }
        if (calle === null) {
            setMessage("Debe seleccionar una calle para reconfirmar el pedido...")
            setCodeAlert(false)
            setOpenAlert(true)
            return
        }
        if (numero === null) {
            setMessage("Debe ingresar un número para reconfirmar el pedido...")
            setCodeAlert(false)
            setOpenAlert(true)
            return
        }
        if (censal.sector === null || censal.sector === '') {
            setMessage("Debe ingresar un sector para reconfirmar el pedido...")
            setCodeAlert(false)
            setOpenAlert(true)
            return
        }

        setOpenSpinner(true)
        updatePedido({
            pedidoId: modalSectorizar,
            comuna: comuna.c_comuna,
            calle: calle.calle,
            numero: numero,
            depto: depto,
            block: block,
            casa: casa,

            camion: 0,

            referencia: referencia,
            censal: censal.sector,
        })
            .then(({ message, status }) => {
                setMessage(message === "" ? "Error al actualizar el pedido" : message)
                setCodeAlert(status === "OK")
                setOpenAlert(true)
                setOpenSpinner(false)

                if (status === "OK") {
                    agregarAnotacion(81, "RECONFIRMADO", modalSectorizar, 1)
                        .then(({ message, status }) => {
                            setMessage(message === "" ? "Error al agregar anotación" : "El pedido fué guardado y reconfirmado exitosamente")
                            setCodeAlert(status === "OK")
                            setOpenAlert(true)
                            setOpenSpinner(false)
                        })
                    handleClose()
                    setTimeout(() => {
                        window.location.reload()
                    }, "1000");
                }
            })
    }

    const censarAutomaticamente = e => {
        e.preventDefault()

        setMessage("Calculando...")
        setCodeAlert(true)
        setOpenAlert(true)

        censadoAutomatico({
            calle: calle.calle,
            numero: numero,
            comuna: comuna.comunaNombre
        })
            .then(({ sector, status }) => {
                if (status === "OK") {
                    setCensal({ sector: sector })
                    handleClose()
                    handleOpen()
                } else {
                    setMessage("No se encontró Sector")
                    setCodeAlert(false)
                    setOpenAlert(true)
                }
            })
    }

    const localizedTextsMap = {
        columnMenuUnsort: "Sin orden",
        columnMenuSortAsc: "Orden ascendente",
        columnMenuSortDesc: "Orden desendente",
        columnMenuFilter: "Filtro",
        columnMenuHideColumn: "Ocultar",
        columnMenuShowColumns: "Mostrar columnas"
    }

    return (
        <div style={{ height: 500, width: '100%' }} >
            <DataGrid
                rows={data}
                columns={allColumns}
                hideFooter={false}
                components={{
                    NoRowsOverlay: () => DataGridNoRows("no-data"),
                }}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = data.filter((row) => selectedIDs.has(row.id.toString()));

                    if (selectedRowData && selectedRowData.length > 0) {

                        const pedidos = new Array();
                        pedidos.push(selectedRowData[0].pedidoid)

                        localStorage.setItem('pedidosList', pedidos);
                        setIsChecked(selectedRowData[0].pedidoid)

                        setModalSectorizar(selectedRowData[0].pedidoid)

                        values.c_comuna = selectedRowData[0].comunaid

                        setComuna({ c_comuna: selectedRowData[0].comunaid, comunaNombre: selectedRowData[0].comuna })
                        setCalle({ calle: selectedRowData[0].calle })
                        setNumero(selectedRowData[0].numero)
                        setDepto(selectedRowData[0].depto)
                        setBlock(selectedRowData[0].block)
                        setCasa(selectedRowData[0].casa)
                        setCamion(selectedRowData[0].camion)
                        setReferencia(selectedRowData[0].referencia)

                        setCensal({ sector: selectedRowData[0].censal })
                        //setOptionsSectores([{ sector: '' }])
                    }
                }}
                localeText={localizedTextsMap}
            />

            <div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <form
                        onSubmit={onHandleSubmit}
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                                Resectorización Pedido {modalSectorizar}
                            </Typography>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <br></br>
                            </Typography>

                            <Grid item>
                                {comunas && comunas.length > 0 &&
                                    <Autocomplete
                                        id="size-small-outlined"
                                        size="small"
                                        className={classes.formControl}
                                        options={comunas}
                                        getOptionLabel={(option) => `${option.c_comuna} - ${option.comunaNombre}`}
                                        defaultValue={comuna}
                                        onChange={(event, newValue) => {
                                            if (!isEmpty(newValue)) {
                                                handleChangeForm("c_comuna", newValue.c_comuna)
                                                setComuna({ c_comuna: newValue.c_comuna, comunaNombre: newValue.comunaNombre })
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Comuna" placeholder="Seleccione Comuna" />
                                        )}
                                    />
                                }
                            </Grid>
                            <br></br><br></br>
                            <Grid item>
                                <Autocomplete
                                    id="calle_select"
                                    className={classes.formControl}
                                    size="small"
                                    defaultValue={calle}
                                    getOptionSelected={(option, value) => option.calle === value.calle}
                                    getOptionLabel={option => option.calle}
                                    onInputChange={(event, value) => onChangeHandle(value)}
                                    options={options}
                                    loading={loading}
                                    autoSelect={false}
                                    onChange={(event, newValue) => {
                                        if (!isEmpty(newValue)) {
                                            handleChangeForm("calle", newValue.calle)
                                            setCalle({ calle: newValue.calle })
                                        }
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label={"Calle"}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                            <br></br><br></br>
                            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" >
                                <InputText
                                    name="numero"
                                    label="Número"
                                    required={true}
                                    type="text"
                                    defaultValue={numero}
                                    value={numero}
                                    onChangeFunction={(e) => setNumero(e.target.value)}
                                    lengthMax={8}
                                    widthStyle='medium'
                                    variant="outlined"
                                />
                                <InputText
                                    name="depto"
                                    label="Depto"
                                    required={false}
                                    type="text"
                                    defaultValue={depto}
                                    value={depto}
                                    onChangeFunction={(e) => setDepto(e.target.value)}
                                    lengthMax={8}
                                    widthStyle='medium'
                                    variant="outlined"
                                />
                                <InputText
                                    name="block"
                                    label="Block"
                                    required={false}
                                    type="text"
                                    defaultValue={block}
                                    value={block}
                                    onChangeFunction={(e) => setBlock(e.target.value)}
                                    lengthMax={8}
                                    widthStyle='medium'
                                    variant="outlined"
                                />
                                <InputText
                                    name="casa"
                                    label="Casa"
                                    required={false}
                                    type="text"
                                    defaultValue={casa}
                                    value={casa}
                                    onChangeFunction={(e) => setCasa(e.target.value)}
                                    lengthMax={8}
                                    widthStyle='medium'
                                    variant="outlined"
                                />
                                <InputText
                                    name="referencia"
                                    label="Referencia"
                                    required={false}
                                    type="text"
                                    defaultValue={referencia}
                                    value={referencia}
                                    onChangeFunction={(e) => setReferencia(e.target.value)}
                                    lengthMax={30}
                                    widthStyle='long'
                                    variant="outlined"
                                />
                            </Grid>
                            <br></br><br></br>
                            <Divider />
                            <br></br><br></br>

                            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" >
                                <Autocomplete
                                    disabled={values.c_comuna == 0}
                                    id="sector_select"
                                    className={classes.formControl}
                                    size="small"
                                    defaultValue={censal}
                                    getOptionSelected={(options, value) => options.sector === value.sector}
                                    getOptionLabel={options => options.sector}
                                    onClickCapture={(event, value) => onChangeSector(value)}
                                    onInputChange={(event, value) => onChangeSector(value)}
                                    options={optionsSector}
                                    //loading={loading}
                                    autoSelect={false}
                                    onChange={(event, newValue) => {
                                        if (!isEmpty(newValue)) {
                                            handleChangeForm("sector", newValue.sector)
                                            setCensal({ sector: newValue.sector.split(' ')[0] })
                                        }
                                    }}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            type='text'
                                            label={"Sector"}
                                            variant="outlined"
                                            style={{ width: 600 }}
                                        //onChange={ e => console.log(e) }
                                        />
                                    )}
                                />
                                <ButtonControl
                                    label="Censar Automáticamente"
                                    //type="secondary"
                                    onClick={censarAutomaticamente}
                                />
                            </Grid>

                            <br></br><br></br>
                            <Divider />
                            <br></br><br></br>

                            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" >
                                <ButtonControl
                                    label="Guardar"
                                    type="primary"
                                />

                                <ButtonControl
                                    label="Guardar y Reconfirmar"
                                    onClick={guardarYReconfirmar}
                                />
                            </Grid>

                        </Box>
                    </form>
                </Modal>
            </div>

            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />

            <div style={{
                paddingTop: 30,
                backgroundColor:
                    'white',
                display: 'flex',
                flexDirection: 'row-reverse'
            }}
            >
            </div>
        </div>
    )
}

