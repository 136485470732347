import React, {useState, useEffect, useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getPedidosByFilters } from '../../helpers/pedido/apiCalls'
import { TableBase } from './TableBase'
import { ExcelExportBase } from '../base/ExcelExportBase'
import { FormBase } from './FormBase'
import { AccionesBase } from './AccionesBase'
import { ButtonPanel } from './ButtonPanel'
import Grid from '@material-ui/core/Grid';
import { GeneralContext } from '../../context/GeneralContext' 
import { isEmpty } from 'lodash'
import Spinner from '../base/Spinner'
import { PopUpBase } from '../base/PopUpBase' 

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));


export const GestorPedidosScreen = () => {
  const { stateGeneral  } = useContext(GeneralContext)
  const [openPopUp, setOpenPopUp] = useState(false)
  const classes = useStyles();
  const [data, setData] = useState({})
  const [reload, setReload] = useState('')
  const [openSpinner, setOpenSpinner] = useState(false)

  useEffect(() => {
    let isMounted = true
    const fetchData =  async () => {
        setOpenSpinner(true)
        getPedidosByFilters(isEmpty(stateGeneral.filtersExportSll) ? {} : stateGeneral.filtersExportSll)
            .then(( result ) => {
                setData(result)
                setOpenSpinner(false)
            })
    }
    fetchData()
    return () => { isMounted = false }
  }, [reload])


    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormBase
              setReload = { setReload }
              reload = { reload }
            />
          </Grid>  
          { data && data.columns &&
            <Grid item xs={12}>
              <ButtonPanel 
                data={ data }
                setOpenPopUp={ setOpenPopUp }
                openPopUp={ openPopUp }
              />
            </Grid>
          }
          { data && data.columns &&
            <Grid item xs={12}>
              <TableBase 
                        getData={ data }
                        data={ data.rows } 
                />
            </Grid>
          }
          { openPopUp &&
            <PopUpBase 
              title={ 'Acciones' }
              open={ openPopUp }
              children={ <AccionesBase /> }
              type={ 'Content' }
              validaWidth={ true }
              maximoW={ 'sm' }
              setOpenPopUp={ setOpenPopUp }
            />

          }
        </Grid>

        <Spinner open={ openSpinner }/>
        
      </div>
    )
}
