import React, { useState, useEffect, useContext } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { InputText } from '../base/controls/InputText'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip, IconButton, isMuiElement } from '@material-ui/core'
import DeleteSweep from '@material-ui/icons/DeleteSweep'
import { styles as dialogStyles } from '../styles/dialogStyles'
import { getComunasAll } from '../../helpers/comuna/apiCalls'
import { addEmergencia, getElementosEnFalla, getTiposServicio, getCondicion, getProductosByFamilia, getTipoEmerByElemFalla, getTipoServByElemFalla, getTipoServByElemEnFalla } from '../../helpers/emergencias/apiCalls'
import { getCallesCallejero } from '../../helpers/calle/apiCalls'
import { GeneralContext } from '../../context/GeneralContext'
import { UserContext } from '../../context/UserContext'
import { DialogAlert } from '../base/DialogAlert'
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Spinner from '../base/Spinner'
import { isEmpty } from 'lodash'
import CircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const FormBase = ({ setReload, reload }) => {
    const classes = useStyles();
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext)
    const { userContextState } = useContext(UserContext)
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [comunas, setComunas] = useState([])
    const [elementosEnFalla, setElementosEnFalla] = useState([])
    const [tiposServicio, setTiposServicio] = useState([{ numero: null, descripcion: "" }])
    const [condicion, setCondicion] = useState([])
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const loading = open && options.length === 0

    const [tipoCliente, setTipoCliente] = useState([])
    const [canal, setCanal] = useState([])
    const [tipo, setTipo] = useState([])
    const [familiaDelProducto, setFamiliaDelProducto] = useState([{ valor: null, familiaDelProducto: "" }])
    const [familiaSelected, setFamiliaSelected] = useState(0)
    const [producto, setProducto] = useState([])

    const [tipoSelected, setTipoSelected] = useState(0)
    //const [tiposEmer, setTiposEmer] = useState('227,228,229,230,231,232,233')
    //const [tiposServ, setTiposServ] = useState('234,235,236,237,238,239,240,241,242,243,244')
    const [elemFalla, setElemFalla] = useState() //('100')

    const [tiposNumero, setTiposNumero] = useState('227');

    const [enableProductos, setEnableProductos] = useState(0)
    const [isEnableProductos, setIsEnableProductos] = useState()
    const [enableTipServ, setEnableTipServ] = useState(0)

    const [famDefault, setFamDefault] = useState()


    const [errorTipoCliente, setErrorTipoCliente] = useState(false)
    const [errorCanal, setErrorCanal] = useState(false)
    const [errorComuna, setErrorComuna] = useState(false)
    const [errorTipo, setErrorTipo] = useState(false)
    const [errorElementosEnFalla, setErrorElementosEnFalla] = useState(false)
    const [errorTiposServicio, setErrorTiposServicio] = useState(false)

    // let enableProductos = false;

    const [values, setValues] = useState({
        estado: 0,
        usuario_crea: 0,
        camion: 0,
        usuario_trans: 0,
        producto: 0,
        observacion: '',
        nombre_cliente: '',
        apellido_cliente: '',
        telefono: '',
        tipo_cliente: 0,
        canal: 0,
        c_comuna: 0,
        calle: '',
        numero: '',
        depto: '',
        block: '',
        casa: '',
        referencia: '',
        tipo: 0,
        elemento_falla: 0,
        tipo_servicio: 0,
        condicion: 0,
        familia_producto: 0
    })

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    useEffect(() => {
        let isMounted = true
        setStateGeneral({ ...stateGeneral, filters: values, observacion: '' })
        setOpenSpinner(true)
        if (comunas.length === 0) {
            getComunasAll()
                .then(({ comunas }) => {
                    if (isMounted) {
                        setComunas(comunas)
                    }
                })
        }

        if (elementosEnFalla.length === 0) {
            getElementosEnFalla()
                .then(({ elementoEnFalla }) => {
                    if (isMounted) {
                        setElementosEnFalla(elementoEnFalla)
                    }
                })
        }

        if (condicion.length === 0) {
            getCondicion()
                .then(({ condicion }) => {
                    if (isMounted) {
                        setCondicion(condicion)
                    }
                })
        }

        if (producto.length === 0) {
            getProductosByFamilia(familiaSelected)
                .then(({ productos }) => {
                    if (isMounted) {
                        setProducto(productos)
                        setFamiliaDelProducto(familiaDelProducto)
                        setFamDefault(familiaDelProducto[0])
                    }
                })
        }

        if (tipoSelected === 1) {
            getTipoEmerByElemFalla(elemFalla)
                .then(({ data }) => {
                    setTiposNumero(data)
                    getTipoServByElemEnFalla(data)
                        .then(({ tipoServicio }) => {
                            setTiposServicio(tipoServicio)
                        })
                })
        } else if (tipoSelected === 2) {
            getTipoServByElemFalla(elemFalla)
                .then(({ data }) => {
                    setTiposNumero(data)
                    getTipoServByElemEnFalla(data)
                        .then(({ tipoServicio }) => {
                            setTiposServicio(tipoServicio)
                        })
                })
        }

        if (isMounted) {
            setFamiliaDelProducto(familiaDelProducto);
            //setFamDefault([{ valor: 1, familiaDelProducto: "ENVASADO" }])
            setFamDefault(familiaDelProducto[0])
        }

        setTipoCliente([
            { valor: 1, tipoCliente: "ENVASADO" },
            { valor: 2, tipoCliente: "GRANEL" },
            { valor: 3, tipoCliente: "AUTOGAS" },
            { valor: 4, tipoCliente: "MEDIDOR" },
            { valor: 5, tipoCliente: "SUBDISTRIBUIDOR EXC" },
            { valor: 6, tipoCliente: "SUBDISTRIBUIDOR MUL" },
            { valor: 7, tipoCliente: "NO OCUPAR" },
            { valor: 8, tipoCliente: "INP" },
            { valor: 9, tipoCliente: "PAES" },
            { valor: 10, tipoCliente: "PREMIUM" },
            { valor: 11, tipoCliente: "SUBDIST.-SAP" },
            { valor: 13, tipoCliente: "CH. COMPRA" },
            { valor: 14, tipoCliente: "AUTOMOCION SURT" },
            { valor: 20, tipoCliente: "COMERCIAL ENVASADO" },
            { valor: 21, tipoCliente: "GAS NATURAL" }
        ]);

        setCanal([
            { valor: 10, canal: "MONOMARCA" },
            { valor: 11, canal: "MULTIMARCA" },
            { valor: 12, canal: "FRANQUICIAS" },
            { valor: 13, canal: "REPOSICION REPARTO" },
            { valor: 15, canal: "REPARTO DOMICILIO" },
            { valor: 20, canal: "LOCAL" },
            { valor: 25, canal: "RESIDENCIAL" },
            { valor: 26, canal: "COMERCIAL" },
            { valor: 27, canal: "INDUSTRIAL" },
            { valor: 28, canal: "GRANEL RESPALDO" },
            { valor: 29, canal: "GRANEL BUTANO" },
            { valor: 30, canal: "MEDIDORES" },
            { valor: 35, canal: "AUTOMOCION" },
            { valor: 40, canal: "AGUA CALIENTE" },
            { valor: 46, canal: "GNL INDUSTRIAL" }
        ]);

        setTipo([
            { valor: 1, tipo: "ET - EMERGENCIA TÉCNICA" },
            { valor: 2, tipo: "SERV - SERVICIO" }
        ]);

        /*  
          setFamiliaDelProducto([
              { valor: 0, familiaDelProducto: "ELIMINADOS" },
              { valor: 1, familiaDelProducto: "ENVASADO" },
              { valor: 2, familiaDelProducto: "GRANEL" },
              { valor: 3, familiaDelProducto: "AUTOGAS" },
              { valor: 4, familiaDelProducto: "GARANTIA" },
              { valor: 5, familiaDelProducto: "GARANTIA AUTOGAS" },
              { valor: 6, familiaDelProducto: "POSTVENTA" },
              { valor: 7, familiaDelProducto: "LEÑA" },
              { valor: 8, familiaDelProducto: "PROMOCIONAL" }
          ]);
          */

        if (elemFalla === '100' || elemFalla === '103') {
            setIsEnableProductos(enableProductos)
        } else {
            setIsEnableProductos(enableProductos)
        }

        if (elemFalla === '100') {
            setFamiliaDelProducto([{ valor: 1, familiaDelProducto: "ENVASADO" }]);
            //setFamDefault(familiaDelProducto[0])
            setFamDefault({ valor: 1, familiaDelProducto: "ENVASADO" })
            //console.log(familiaDelProducto[0])
        } else if (elemFalla === '103') {
            setFamiliaDelProducto([{ valor: 3, familiaDelProducto: "AUTOGAS" }]);
            //setFamDefault(familiaDelProducto[0])
            setFamDefault({ valor: 3, familiaDelProducto: "AUTOGAS" })
            //console.log(familiaDelProducto[0])
        } else {
            setFamiliaDelProducto([{ valor: null, familiaDelProducto: "" }]);
            setFamDefault({ valor: null, familiaDelProducto: "" })
            //setFamDefault({ valor: 9, familiaDelProducto: " - " })
        }

        setTiposServicio([{ numero: null, descripcion: "" }])

        setOpenSpinner(false)
        return () => { isMounted = false }
    }, [reload])


    const handleChangeForm = (name, event) => {
        if (name === 'nombre_cliente' || name === 'apellido_cliente' || name === 'observacion' || name === 'referencia' || name === 'c_comuna' || name === 'calle'
            || name === 'numero' || name === 'depto' || name === 'block' || name === 'casa' || name === 'tipo_cliente' || name === 'canal'
            || name === 'tipo' || name === 'elemento_falla' || name === 'tipo_servicio' || name === 'condicion' || name === 'familia_producto' || name === 'producto') {
            setValues({ ...values, [name]: event })

            if (name === 'nombre_cliente' || name === 'apellido_cliente' || name === 'observacion' || name === 'referencia'
                || name === 'numero' || name === 'depto' || name === 'block' || name === 'casa') {
                setValues({ ...values, [name]: event.target.value.toUpperCase() })
            }

            if (name === 'familia_producto') {
                setFamiliaSelected(event)
                const { productos } = getProductosByFamilia(familiaSelected).then((data) => { return data })
                if (productos && productos.length > 0) {
                    setProducto(productos)
                } else {
                    setProducto([])
                }
            }

            if (name === 'tipo') {
                setTipoSelected(event)

                if (event === null) {
                    setTipoSelected(0)
                }
            }

            if (name === 'elemento_falla') {
                setElemFalla(event)

                if (event === null) {
                    setEnableTipServ(0)
                } else {
                    setEnableTipServ(1)
                }


                if (event === '100' || event === '103') {
                    setEnableProductos(1)
                } else {
                    setEnableProductos(0)
                }

                if (event === '100') {
                    setFamiliaSelected(1)
                    setFamiliaDelProducto([{ valor: 1, familiaDelProducto: "ENVASADO" }]);
                    //setFamDefault({ valor: 1, familiaDelProducto: "ENVASADO" })
                    const { productos } = getProductosByFamilia(familiaSelected).then((data) => { return data })
                    if (productos && productos.length > 0) {
                        setProducto(productos)
                    } else {
                        setProducto([]);
                    }
                } else if (event === '103') {
                    setFamiliaSelected(3)
                    setFamiliaDelProducto([{ valor: 3, familiaDelProducto: "AUTOGAS" }]);
                    // setFamDefault({ valor: 3, familiaDelProducto: "AUTOGAS" })
                    const { productos } = getProductosByFamilia(familiaSelected).then((data) => { return data })
                    if (productos && productos.length > 0) {
                        setProducto(productos);
                    } else {
                        setProducto([]);
                    }
                } else {
                    setFamiliaDelProducto([{ valor: null, familiaDelProducto: "" }]);
                    // setFamDefault({ valor: null, familiaDelProducto: "" })
                }

                if (tipoSelected === 1) {
                    getTipoEmerByElemFalla(elemFalla)
                        .then(({ data }) => {
                            setTiposNumero(data)
                            getTipoServByElemEnFalla(data)
                                .then(({ tipoServicio }) => {
                                    setTiposServicio(tipoServicio)
                                })
                        })
                } else if (tipoSelected === 2) {
                    getTipoServByElemFalla(elemFalla)
                        .then(({ data }) => {
                            setTiposNumero(data)
                            getTipoServByElemEnFalla(data)
                                .then(({ tipoServicio }) => {
                                    setTiposServicio(tipoServicio)
                                })


                        })
                }
            }

            setStateGeneral({ ...stateGeneral, comunaSll: event })
            setReload(new Date())
        } else {
            setValues({ ...values, [name]: event.target.value })
            setStateGeneral({ ...stateGeneral, filters: [values] })
        }
    }

    const [selectedValue, setSelectedValue] = useState(null);
    const handleInputChange = (obj, value) => {
        setSelectedValue({ ...values, ['calle']: value });
        setValues({ ...values, ['calle']: value });
    };

    const cleanData = () => {
        setValues({
            estado: 0,
            usuario_crea: 0,
            camion: 0,
            usuario_trans: 0,
            producto: 0,
            observacion: '',
            nombre_cliente: '',
            apellido_cliente: '',
            telefono: '',
            c_comuna: 0,
            calle: '',
            numero: '',
            depto: '',
            block: '',
            casa: '',
            referencia: '',
            elemento_falla: 0,
            tipo_servicio: 0,
            condicion: 0
        })
        setStateGeneral({ ...stateGeneral, observacion: '' })
        setComunas([])
        setElementosEnFalla([])
        setTiposServicio([])
        setCondicion([])
        setTipoCliente([])
        setCanal([])
        setTipo([])
        setFamiliaDelProducto([])
        setProducto([])
        setSelectedValue(null)
        setReload(new Date())
    }

    const onHandleSubmit = e => {
        e.preventDefault()

        const telefono = values.telefono;
        if (telefono.length >= 9) {
            setOpenSpinner(true)
            addEmergencia({
                estado: 1,
                usuario_crea: localStorage.getItem("usuario"),
                camion: 0,
                usuario_trans: 0,
                producto: values.producto,
                observacion: values.observacion,
                nombre_cliente: values.nombre_cliente,
                apellido_cliente: values.apellido_cliente,
                telefono: values.telefono,
                tipo_cliente: values.tipo_cliente,
                canal: values.canal,
                c_comuna: values.c_comuna,
                calle: values.calle.toUpperCase(),
                numero: values.numero,
                depto: values.depto,
                block: values.block,
                casa: values.casa,
                referencia: values.referencia,
                tipo: values.tipo,
                elemento_falla: values.elemento_falla,
                tipo_servicio: values.tipo_servicio,
                condicion: values.condicion,
                familia_producto: values.familia_producto
            })
                .then(({ message, status }) => {
                    setMessage(message === "" ? "Error al crear la emergencia" : message)
                    setCodeAlert(status === "OK")
                    setOpenAlert(true)
                    cleanData()
                    setOpenSpinner(false)
                })
            handleChangeForm("telefono", { target: { value: telefono } });
        } else {
            setMessage("El teléfono debe tener al menos 9 dígitos")
            setCodeAlert(false)
            setOpenAlert(true)
            setOpenSpinner(false)
        }
    }

    const onChangeHandle = async value => {
        const { calles } = await getCallesCallejero(values.c_comuna, value).then((data) => { return data })
        if (calles && calles.length > 0) {
            setOptions(calles)
        } else {
            setOptions([{ idCalle: 0, calle: value }])
        }
    };

    return (

        <div className={classes.root}>
            <form
                onSubmit={onHandleSubmit}
            >
                <Grid
                    container
                    spacing={3}
                    style={{
                        backgroundColor: 'white',
                        padding: 20
                    }}
                >
                    <Grid item xs={9}>
                        <Grid justifycontent="left" spacing={1}>
                            <Grid container justifycontent="left" spacing={1}>
                                <Grid item>
                                    <InputText
                                        name="nombre_cliente"
                                        label="Nombre Cliente"
                                        required={true}
                                        type="text"
                                        value={values.nombre_cliente.replace(/[^a-zA-Z0-9\s#.,]/g, '')}
                                        onChangeFunction={(e) => handleChangeForm("nombre_cliente", e)}
                                        lengthMax={100}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="apellido_cliente"
                                        label="Apellido Cliente"
                                        required={true}
                                        type="text"
                                        value={values.apellido_cliente.replace(/[^a-zA-Z0-9\s#.,]/g, '')}
                                        onChangeFunction={(e) => handleChangeForm("apellido_cliente", e)}
                                        lengthMax={100}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText className={classes.formControlFono}
                                        name="telefono"
                                        label="Teléfono (56)"
                                        required={true}
                                        type="number"
                                        value={values.telefono}
                                        lengthMax={9}
                                        onChangeFunction={(e) => handleChangeForm("telefono", e)}
                                    />
                                </Grid>
                                <Grid item>
                                    {tipoCliente && tipoCliente.length > 0 && (
                                        <Autocomplete
                                            id="size-small-outlined"
                                            size="small"
                                            className={classes.formControl}
                                            options={tipoCliente}
                                            getOptionLabel={(option) => `${option.valor} - ${option.tipoCliente}`}
                                            defaultValue={null}
                                            onChange={(event, newValue) => {
                                                if (!isEmpty(newValue)) {
                                                    handleChangeForm("tipo_cliente", newValue.valor);
                                                    setErrorTipoCliente(false);
                                                } else {
                                                    handleChangeForm("tipo_cliente", null);
                                                    setErrorTipoCliente(true);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Tipo Cliente"
                                                    placeholder="Tipo Cliente"
                                                    required={true}
                                                    error={errorTipoCliente}
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    {canal && canal.length > 0 && (
                                        <Autocomplete
                                            id="size-small-outlined"
                                            size="small"
                                            className={classes.formControl}
                                            options={canal}
                                            getOptionLabel={(option) => `${option.valor} - ${option.canal}`}
                                            defaultValue={null}
                                            onChange={(event, newValue) => {
                                                if (!isEmpty(newValue)) {
                                                    handleChangeForm("canal", newValue.valor);
                                                    setErrorCanal(false);
                                                } else {
                                                    handleChangeForm("canal", null);
                                                    setErrorCanal(true);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Canal"
                                                    placeholder="Canal"
                                                    required={true}
                                                    error={errorCanal}
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container justifycontent="left" spacing={1}>
                                <Grid item>
                                    {comunas && comunas.length > 0 && (
                                        <Autocomplete
                                            id="size-small-outlined"
                                            size="small"
                                            className={classes.formControl}
                                            options={comunas}
                                            getOptionLabel={(option) => `${option.c_comuna} - ${option.comunaNombre}`}
                                            defaultValue={null}
                                            onChange={(event, newValue) => {
                                                if (!isEmpty(newValue)) {
                                                    handleChangeForm("c_comuna", newValue.c_comuna);
                                                    setErrorComuna(false);
                                                } else {
                                                    handleChangeForm("c_comuna", null);
                                                    setErrorComuna(true);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Comuna"
                                                    placeholder="Seleccione Comuna"
                                                    required={true}
                                                    error={errorComuna}
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        value={selectedValue}
                                        id="calle_select"
                                        className={classes.formControl}
                                        size="small"
                                        style={{ width: 500 }}
                                        open={open}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        getOptionSelected={(option, value) => option.calle === value.calle}
                                        getOptionLabel={option => option.calle}
                                        onInputChange={handleInputChange}
                                        options={options}
                                        loading={loading}
                                        autoSelect={false}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Calles"
                                                variant="outlined"
                                                required={true}
                                                onChange={ev => {
                                                    // dont fire API if the user delete or not entered anything
                                                    if (ev.target.value !== "" || ev.target.value !== null) {
                                                        onChangeHandle(ev.target.value);
                                                    }
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loading ? (
                                                                <CircularProgress color="inherit" size={20} />
                                                            ) : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="numero"
                                        label="Número"
                                        required={true}
                                        type="text"
                                        value={values.numero.replace(/[^a-zA-Z0-9\s#.,]/g, '')}
                                        onChangeFunction={(e) => handleChangeForm("numero", e)}
                                        lengthMax={8}
                                        widthStyle='small'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifycontent="left" spacing={1}>
                                <Grid item>
                                    <InputText
                                        name="depto"
                                        label="Depto"
                                        required={false}
                                        type="text"
                                        value={values.depto.replace(/[^a-zA-Z0-9\s#.,]/g, '')}
                                        onChangeFunction={(e) => handleChangeForm("depto", e)}
                                        lengthMax={100}
                                        widthStyle='small'
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="block"
                                        label="Block"
                                        required={false}
                                        type="text"
                                        value={values.block.replace(/[^a-zA-Z0-9\s#.,]/g, '')}
                                        onChangeFunction={(e) => handleChangeForm("block", e)}
                                        lengthMax={100}
                                        widthStyle='small'
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="casa"
                                        label="Casa"
                                        required={false}
                                        type="text"
                                        value={values.casa.replace(/[^a-zA-Z0-9\s#.,]/g, '')}
                                        onChangeFunction={(e) => handleChangeForm("casa", e)}
                                        lengthMax={100}
                                        widthStyle='small'
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="referencia"
                                        label="Referencia"
                                        required={false}
                                        type="text"
                                        value={values.referencia.replace(/[^a-zA-Z0-9\s#.,]/g, '')}
                                        onChangeFunction={(e) => handleChangeForm("referencia", e)}
                                        lengthMax={100}
                                        widthStyle='long'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid justifycontent="left" spacing={1}>
                            <Grid container justifycontent="left" spacing={1}>
                                <Grid item>
                                    {tipo && tipo.length > 0 && (
                                        <Autocomplete
                                            id="size-small-outlined"
                                            size="small"
                                            className={classes.formControl}
                                            options={tipo}
                                            getOptionLabel={(option) => `${option.tipo}`}
                                            defaultValue={null}
                                            onChange={(event, newValue) => {
                                                if (!isEmpty(newValue)) {
                                                    handleChangeForm("tipo", newValue.valor);
                                                    setErrorTipo(false);
                                                } else {
                                                    handleChangeForm("tipo", null);
                                                    setErrorTipo(true);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Tipo"
                                                    placeholder="Tipo"
                                                    required={true}
                                                    error={errorTipo}
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    {elementosEnFalla && elementosEnFalla.length > 0 && (
                                        <Autocomplete
                                            id="size-small-outlined"
                                            size="small"
                                            disabled={tipoSelected === 0 ? true : false}
                                            className={classes.formControl}
                                            options={elementosEnFalla}
                                            getOptionLabel={(option) => `${option.numero} - ${option.descripcion}`}
                                            defaultValue={null}
                                            onChange={(event, newValue) => {
                                                if (!isEmpty(newValue)) {
                                                    handleChangeForm("elemento_falla", newValue.numero);
                                                    setErrorElementosEnFalla(false);
                                                } else {
                                                    handleChangeForm("elemento_falla", null);
                                                    setErrorElementosEnFalla(true);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Elemento en Falla"
                                                    placeholder="Elemento en Falla"
                                                    required={true}
                                                    error={errorElementosEnFalla}
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    {tiposServicio && tiposServicio.length > 0 && (
                                        <Autocomplete
                                            id="size-small-outlined"
                                            size="small"
                                            disabled={enableTipServ === 1 ? false : true}
                                            className={classes.formControl}
                                            options={tiposServicio}
                                            getOptionLabel={(option) => `${option.numero} - ${option.descripcion}`}
                                            defaultValue={null}
                                            onChange={(event, newValue) => {
                                                if (!isEmpty(newValue)) {
                                                    handleChangeForm("tipo_servicio", newValue.numero);
                                                    setErrorTiposServicio(false);
                                                } else {
                                                    handleChangeForm("tipo_servicio", null);
                                                    setErrorTiposServicio(true);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Tipo Servicio"
                                                    placeholder="Tipo Servicio"
                                                    required={true}
                                                    error={errorTiposServicio}
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    {condicion && condicion.length > 0 &&
                                        <Autocomplete
                                            id="size-small-outlined"
                                            size="small"
                                            disabled={tipoSelected === 1 ? false : true}
                                            className={classes.formControl}
                                            options={condicion}
                                            getOptionLabel={(option) => `${option.art_codigo} - ${option.art_descripcion}`}
                                            defaultValue={null}
                                            onChange={(event, newValue) => {
                                                if (!isEmpty(newValue)) {
                                                    handleChangeForm("condicion", newValue.art_codigo)
                                                } else {
                                                    handleChangeForm("condicion", null)
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Condición" placeholder="Condición" />
                                            )}
                                        />
                                    }
                                </Grid>
                            </Grid>
                            <Grid container justifycontent="left" spacing={1}>
                                <Grid item>
                                    {familiaDelProducto && familiaDelProducto.length > 0 && famDefault && //famDefault.length > 0 &&
                                        <Autocomplete
                                            id="size-small-outlined"
                                            size="small"
                                            disabled={enableProductos === 1 ? false : true}
                                            className={classes.formControl}
                                            options={familiaDelProducto}
                                            getOptionLabel={(option) => `${option.familiaDelProducto}`}
                                            defaultValue={famDefault} //[0]
                                            //defaultValue={null}
                                            onChange={(event, newValue) => {
                                                if (!isEmpty(newValue)) {
                                                    handleChangeForm("familia_producto", newValue.valor)
                                                } else {
                                                    handleChangeForm("familia_producto", null)
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Familia del Producto" placeholder="Familia del Producto" />
                                            )}
                                        />
                                    }
                                </Grid>
                                <Grid item>
                                    {producto && producto.length > 0 &&
                                        <Autocomplete
                                            id="size-small-outlined"
                                            size="small"
                                            disabled={enableProductos === 1 ? false : true}
                                            className={classes.formControl}
                                            options={producto}
                                            getOptionLabel={(option) => `${option.descripcion}`}
                                            defaultValue={null}
                                            onChange={(event, newValue) => {
                                                if (!isEmpty(newValue)) {
                                                    handleChangeForm("producto", newValue.producto)
                                                } else {
                                                    handleChangeForm("producto", null)
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Producto" placeholder="Producto" />
                                            )}
                                        />
                                    }
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="observacion"
                                        label="Observación"
                                        required={false}
                                        type="text"
                                        value={values.observacion.replace(/[^a-zA-Z0-9\s#.,]/g, '')}
                                        onChangeFunction={(e) => handleChangeForm("observacion", e)}
                                        lengthMax={100}
                                        widthStyle='long'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container spacing={1} direction="row" style={{ alignItems: 'flex-end', flexDirection: 'row-reverse' }}>
                        <Grid item style={{ marginTop: 8 }}>
                            <ButtonControl
                                label="Crear Emergencia"
                                type="primary"
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 8 }}>
                            <Tooltip title={`Limpiar`}>
                                <IconButton
                                    aria-label="Filter list"
                                    onClick={cleanData}
                                    style={dialogStyles.cleanFilters}
                                >
                                    <DeleteSweep
                                        fontSize={'large'}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </form>

            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />

        </div>
    )
}