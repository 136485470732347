import React, { useState } from 'react'
import DataGridNoRows from '../../common/DataGridNoRows'
import { DataGrid } from '@material-ui/data-grid'
import { getAnotacionesPedido } from '../../helpers/pedido/apiCalls'
import { Radio, Modal, Box, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Spinner from '../base/Spinner'

export const TableBase = ({ getData, data = [] }) => {

    const { columns } = getData
    const [isChecked, setIsChecked] = useState(null)
    const [modalAnotaciones, setModalAnotaciones] = useState(0)

    const [anotacionesPedido, setAnotacionesPedido] = useState(['null'])

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openSpinner, setOpenSpinner] = useState(false)


    const columnOption = {
        field: "✓", headerName: "", width: 10, sortable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center',
        renderCell: (data) => (
            <Radio checked={data.row.pedidoid == isChecked} value={data.row.pedidoid} color='primary' size="small" />
        )
    }

    const columnAnotaciones = {
        field: "☰", headerName: "", width: 10, sortable: false, disableColumnMenu: true, headerAlign: 'center', align: 'center', description: 'Anotaciones',
        renderCell: (modal) => (
            <button onDoubleClick={openModalAnotaciones} title="Anotaciones ¡Doble Click!">☰</button>
        )
    }

    const openModalAnotaciones = () => {

        handleOpen()
        setOpenSpinner(true)

        getAnotacionesPedido(modalAnotaciones)
            .then(({ anotaciones }) => {

                if (anotaciones && anotaciones.length > 0) {
                    const anotacionesList = new Array();

                    for (let i = 0; i < anotaciones.length; i++) {
                        anotacionesList.push(anotaciones[i])
                    }

                    setAnotacionesPedido(anotacionesList)
                    setOpenSpinner(false)
                } else {
                    const anotacionesList = new Array();
                    const texto = 'No existen anotaciones para este pedido'
                    anotacionesList.push({ id: '', observacion: texto })

                    setAnotacionesPedido(anotacionesList)
                    setOpenSpinner(false)
                }
            })
    }

    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });

    const classes = useStyles();

    const allColumns = [columnOption, columnAnotaciones].concat(columns)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const localizedTextsMap = {
        columnMenuUnsort: "Sin orden",
        columnMenuSortAsc: "Orden ascendente",
        columnMenuSortDesc: "Orden desendente",
        columnMenuFilter: "Filtro",
        columnMenuHideColumn: "Ocultar",
        columnMenuShowColumns: "Mostrar columnas"
    }

    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={allColumns}
                hideFooter={false}
                components={{
                    NoRowsOverlay: () => DataGridNoRows("no-data"),
                }}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = data.filter((row) => selectedIDs.has(row.id.toString()));

                    if (selectedRowData && selectedRowData.length > 0) {
                        console.log(selectedRowData)

                        const pedidos = new Array();
                        pedidos.push(selectedRowData[0].pedidoid)

                        localStorage.setItem('pedidosList', pedidos);
                        setIsChecked(selectedRowData[0].pedidoid)

                        setModalAnotaciones(selectedRowData[0].pedidoid)
                    }
                }}
                localeText={localizedTextsMap}
            />


            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align='center'>
                            Anotaciones Pedido {modalAnotaciones}
                        </Typography>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <br></br>
                            <Spinner open={openSpinner} />
                        </Typography>

                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>

                                    {anotacionesPedido.map((anot) => (
                                        <TableRow key={anot.id}>
                                            <TableCell component="th" scope="row">
                                                {anot.fk_tipo_anotacion}
                                            </TableCell>
                                            <TableCell align="left">{anot.descripcion_anotacion}</TableCell>
                                            <TableCell align="left">{anot.observacion}</TableCell>
                                            <TableCell align="left">{anot.fechahora_crea}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                </Modal>

            </div>


            <div style={{
                paddingTop: 30,
                backgroundColor:
                    'white',
                display: 'flex',
                flexDirection: 'row-reverse'
            }}
            >
            </div>
        </div>
    )
}

