import React, { useContext } from 'react'
import { TableRow, TableCell, Table, TableBody } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { GeneralContext } from '../../context/GeneralContext' 


export const Summary = () => {
    const { stateGeneral } = useContext(GeneralContext);
    
    return (
        <>
        <Table>
            <TableBody>
                    <TableRow key={1}>
                        <TableCell component="th" scope="row">
                            <Typography style={ styleSummary.subtitle }>Sub-Total:</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            { `$${stateGeneral.totales ? stateGeneral.totales.subTotal : 0}`  }
                        </TableCell>
                    </TableRow>
                    <TableRow key={2}>
                        <TableCell component="th" scope="row">
                            <Typography style={ styleSummary.subtitle }>Descuentos:</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            { `$${stateGeneral.totales ? stateGeneral.totales.descuento : 0}`  }
                        </TableCell>
                    </TableRow>
                    <TableRow key={3}>
                        <TableCell component="th" scope="row">
                            <Typography style={ styleSummary.subtitle }>Total a Pagar:</Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                            { `$${stateGeneral.totales ? stateGeneral.totales.total : 0}`  }
                        </TableCell>
                    </TableRow>
            </TableBody>
        </Table>
        </>
    )
}


const styleSummary = {
    subtitle: { 
        fontSize: 'small', 
        fontWeight: 'bold' 
    },
}