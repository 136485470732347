import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormBase } from '../toma-emergencias/FormBase'
import Grid from '@material-ui/core/Grid';
import Spinner from '../base/Spinner';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    }
  }));

export const TomaEmergenciasScreen = () => {
    const classes = useStyles();
    const [reload, setReload] = useState('')
    const [openSpinner, setOpenSpinner] = useState(false)
    
    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <FormBase 
                        setReload = { setReload }
                        reload = { reload }
                    />
                </Grid>
            </Grid>
            <Spinner open={ openSpinner }/>
        </div>
    )
}