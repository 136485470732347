import React, { useState, useEffect, useContext } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
import { getComunasAll } from '../../helpers/comuna/apiCalls'
import { getEstadosAll } from '../../helpers/estado/apiCalls'
import { GeneralContext } from '../../context/GeneralContext' 
import { DialogAlert } from '../base/DialogAlert'
import Spinner from '../base/Spinner'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid';
import moment from 'moment'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));
  


export const FormBase = ({ setReload, reload  }) => {
    const classes = useStyles();
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext)
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [comunas, setComunas] = useState([])
    const [estados, setEstados] = useState([])

    const [values, setValues] = useState({
        c_comuna: null,
        estado: 3,
        fechaTomaInicio: moment(new Date()).format("DD-MM-YYYY"),
        fechaTomaFin: moment(new Date()).format("DD-MM-YYYY")
    })
        
    const handleCloseAlert = () => {
        if(openAlert) { 
            setOpenAlert(false)
        }     
    }

    useEffect(() => {
        let isMounted = true
        setStateGeneral({ ...stateGeneral, filters: values })
        setOpenSpinner(true)
        getComunasAll()
            .then(({ comunas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setComunas(comunas)
                }
            })
        setOpenSpinner(true)
        getEstadosAll()
            .then(({ estados }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setEstados(estados)
                }
            })
        return () => { isMounted = false }
    },[])

    const handleChangeForm = (name, event) => {
        if(name === 'c_comuna' || name === 'estado') {
            setValues({ ...values, [name]: event })
        } else if(name === "fechaTomaInicio" || name === "fechaTomaFin") {
            setValues({ ...values, [name]: moment(document.getElementById(name).value).format("DD-MM-YYYY") })
        } else {
            setValues({ ...values, [name]: event.target.value })
        }
    }
    
    const onHandleSubmit = e => {
        e.preventDefault()
        setStateGeneral({ ...stateGeneral, filtersExportSll: values })
        setReload(new Date())
    }

    return (
        <div className={classes.root}>
            <form  onSubmit={ onHandleSubmit }>
            <Grid 
                container 
                spacing={3} 
                style={{ 
                    backgroundColor: 'white',
                    padding: 20
                }}
                > 
                 <Grid item xs={12}>
                    <Grid container justifycontent="center" spacing={8}>
                        <Grid item>
                            <TextField
                                id="fechaTomaInicio"
                                label="Fecha Inicio"
                                type="date"
                                defaultValue={ moment(new Date()).format("YYYY-MM-DD") }
                                className={classes.textField}
                                onChange={(event, newValue) => {
                                    handleChangeForm("fechaTomaInicio", newValue)
                                }}
                                InputLabelProps={{
                                shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="fechaTomaFin"
                                label="Fecha Fin"
                                type="date"
                                defaultValue={ moment(new Date()).format("YYYY-MM-DD") }
                                className={classes.textField}
                                onChange={(event, newValue) => {
                                    handleChangeForm("fechaTomaFin", newValue)
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                        {   estados && estados.length > 0 &&
                                <Autocomplete
                                    id="size-small-outlined"
                                    size="small"
                                    className= { classes.formControl  }
                                    options={estados}
                                    getOptionLabel={(option) => `${option.estado} - ${option.descripcion}`}
                                    defaultValue={estados[2]}
                                    onChange={(event, newValue) => {
                                        if(!isEmpty(newValue)) {
                                            handleChangeForm("estado", newValue.estado)
                                        } else {
                                            handleChangeForm("estado", null)
                                        }
                                    }}
                                    renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Estado" placeholder="Seleccione Estado" />
                                    )}
                                />
                                }
                        </Grid>
                        <Grid item>
                            {   comunas && comunas.length > 0 &&
                                <Autocomplete
                                    id="size-small-outlined"
                                    size="small"
                                    className= { classes.formControl  }
                                    options={comunas}
                                    getOptionLabel={(option) => `${option.c_comuna} - ${option.comunaNombre}`}
                                    defaultValue={comunas[0]}
                                    onChange={(event, newValue) => {
                                        if(!isEmpty(newValue)) {
                                            handleChangeForm("c_comuna", newValue.c_comuna)
                                        } else {
                                            handleChangeForm("c_comuna", null)
                                        }
                                    }}
                                    renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Comuna" placeholder="Seleccione Comuna" />
                                    )}
                                />
                                }
                        </Grid>
                        <Grid item style={{ marginTop: 8 }}>
                            <ButtonControl
                                label="Filtrar"
                                type="primary"
                            />
                        </Grid>
                    </Grid>
                 </Grid>
            </Grid>
            </form>
            { openAlert  &&
                    <DialogAlert 
                        title="Alerta"
                        message= { message }
                        type="alert"
                        code={ codeAlert }
                        method="DELETE"
                        openAlert={ openAlert }
                        handleCloseAlert={ handleCloseAlert }
                    />
                }
                <Spinner open={ openSpinner }/>
            </div>
    )
}


