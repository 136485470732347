import React, { useState, createContext } from 'react'

const menuOptionDefault = [
    {
        page: "toma-pedidos",
        default: "toma-pedidos",
        name: "toma-pedidos",
        path: "toma-pedidos",
        permiso: 900,
        text: 'Toma Pedidos',
    },
    {
        page: "gestion-sll",
        default: "gestion-sll",
        name: "gestion-sll",
        path: "gestion-sll",
        permiso: 901,
        text: 'Gestion Sll',
    },
    {
        page: "transmision",
        default: "transmision",
        name: "transmision",
        path: "transmision",
        permiso: 902,
        text: 'Transmision',
    },
    {
        page: "sectorizacion",
        default: "sectorizacion",
        name: "sectorizacion",
        path: "sectorizacion",
        permiso: 903,
        text: 'Sectorizacion',
    },
    {
        page: "moviles",
        default: "moviles",
        name: "moviles",
        path: "moviles",
        permiso: 904,
        text: 'Moviles',
    },
    {
        page: "descuentos",
        default: "descuentos",
        name: "descuentos",
        path: "descuentos",
        permiso: 905,
        text: 'Descuentos',
    },
    /*
    {
        page: "gestor-pedidos",
        default: "gestor-pedidos",
        name: "gestor-pedidos",
        path: "gestor-pedidos",
    },
    */
    {
        page: "administrador",
        default: "administrador",
        name: "administrador",
        path: "administrador",
        permiso: 906,
        text: 'Administrador',
    },
    {
        page: "toma-emergencias",
        default: "toma-emergencias",
        name: "toma-emergencias",
        path: "toma-emergencias",
        permiso: 909,
        text: 'Toma Emergencias',
    },
    {
        page: "transmision-emergencias",
        default: "transmision-emergencias",
        name: "transmision-emergencias",
        path: "transmision-emergencias",
        permiso: 910,
        text: 'Transmision Emergencias',
    },
    {
        page: "gestion-sll-emergencias",
        default: "gestion-sll-emergencias",
        name: "gestion-sll-emergencias",
        path: "gestion-sll-emergencias",
        permiso: 911,
        text: 'Gestion Sll - Emergencias',
    }
]

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const [userContextState, setUserContextState] = useState({
        menu: menuOptionDefault,
        claims: []
    })

    return(
        <UserContext.Provider value={{
            userContextState, setUserContextState
        }}>
            { children }
        </UserContext.Provider>
   )
  
}
