import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, IconButton } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AppsIcon from '@material-ui/icons/Apps';
import Grid from '@material-ui/core/Grid';
import { ExcelExportBase } from '../base/ExcelExportBase'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}))

export const ButtonPanel = ({ data, setOpenPopUp }) => {
    const classes = useStyles()
    const handleIconClicks = () => {
        setOpenPopUp(true)
    }

    const copyToClipBoard = () =>  { 
        alert("Pedidos copiados...")
    }

  return (
    <div className={classes.root}>
        <Grid 
            container 
            //spacing={ 3 } 
            style={{ 
                 backgroundColor: 'white'
            }}
             
        > 
            <Grid item>
                <Tooltip title={`Generar Acciones`}>
                    <IconButton name='hols' onClick={ handleIconClicks } color="primary" aria-label="delete">
                        <AppsIcon /> 
                        <Typography style={{ paddingLeft: 10 }} component={'span'}>
                                Acciones
                        </Typography>
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title={`Copiar Pedidos`}>
                    <IconButton onClick={ copyToClipBoard }aria-label="delete">
                        <FileCopyIcon />
                        <Typography style={{ paddingLeft: 10 }} component={'span'}>
                                Copiar
                        </Typography>
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <ExcelExportBase 
                    header={ data.columns }
                    data={ data.rows }
                    title={ '_pedidos_' }
                />
            </Grid>
      </Grid>
    </div>
  );
}
