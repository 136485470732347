import moment from 'moment'
import { isEmpty } from 'lodash'
import { Radio } from '@material-ui/core'
//const apiUrl = process.env.REACT_APP_API_URL
const apiRestUrl = process.env.REACT_APP_API_REST_URL
const apiLogisticaUrl = process.env.REACT_APP_API_LOGISTICA_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')


const token = localStorage.getItem('token');

export const asignarCamion = async (camion, pedidos, pedidosLength) => {
	//const url = `${ apiUrl }/transmision`
	const url = `${apiRestUrl}/pedido/asignarCamion`
	//myHeaders.set('Authorization', `Bearer ${ token }`)

	const raw = {
		"camion": camion,
		"pedidos": pedidos,
		"pedidosLength": pedidosLength,
	};

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201) {
			const { mensaje_usr, mensaje_sis } = await resp.json()
			return {
				message: mensaje_usr, //'Camión asignado correctamente',//mensajeRespuesta,
				status: mensaje_sis //'OK'
			}
		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para asignar el camión`
		}
	}
}

export const transmitir = async (pedidos, pedidosLength) => {
	//const url = `${ apiUrl }/transmision`
	const url = `${apiRestUrl}/pedido/transmisionAutomatica`
	//myHeaders.set('Authorization', `Bearer ${ token }`)

	const raw = {
		"pedidos": pedidos,
		"pedidosLength": pedidosLength
	};

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})
		
		if (resp.status === 200 || resp.status === 201) {
			const { mensaje_usr, mensaje_sis } = await resp.json()

			return {
				message: mensaje_usr, //'Transmitido correctamente',//mensajeRespuesta,
				status: mensaje_sis
			}
		} else if (resp.status === 204) {
			return {
				message: 'No hay terminal disponible',//mensajeRespuesta,
				status: 'NOK'
			}
		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		console.log(err)
		return {
			status: 'NOK',
			message: `No se pudo conectar para transmitir`
		}
	}
}

export const agregarAnotacion = async (tipoanot, observacion, pedidos, pedidosLength) => {
	//const url = `${ apiUrl }/transmision`
	const url = `${apiRestUrl}/pedido/agregarAnotacion`
	//myHeaders.set('Authorization', `Bearer ${ token }`)

	const raw = {
		"tipoanot": tipoanot,
		"observacion": observacion,
		"pedidos": pedidos,
		"pedidosLength": pedidosLength,
		"usuario": localStorage.getItem('usuario')
	};

	try {
		const resp = await fetch(url, {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const { mensajeRespuesta } = await resp.json()
			return {
				message: 'Anotación agregada correctamente',//mensajeRespuesta,
				status: 'OK'
			}
		} else {
			return {
				status: 'NOK',
				message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para agregar la anotación`
		}
	}
}

export const getTransmisionByFilters = async (values) => {
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	/*const requestParams = {
		c_comuna: isEmpty(values) ? 0 : values.c_comuna, 
		estado: isEmpty(values) ? 1 : values.estado,  
		fechaTomaInicio: isEmpty(values) ? moment(new Date()).format("DDMMYYYY") : moment(values.fechaTomaInicio, 'DD-MM-YYYY').format("DDMMYYYY"),  
		fechaTomaFin: isEmpty(values) ? moment(new Date()).format("DDMMYYYY") : moment(values.fechaTomaFin, 'DD-MM-YYYY').format("DDMMYYYY"),  
	}*/
	//const { c_comuna, estado, fechaTomaInicio, fechaTomaFin } = requestParams

	//const url = `${ apiUrl }/transmision?c_comuna=${c_comuna}&estado=${estado}&fechaTomaInicio=${fechaTomaInicio}&fechaTomaFin=${fechaTomaFin}`
	const url = `${apiRestUrl}/pedido`



	const raw = {
		"estado": isEmpty(values) ? 1 : values.estado,
		"fechaTomaInicio": isEmpty(values) ? moment(new Date()).format("DDMMYYYY") : moment(values.fechaTomaInicio, 'DD-MM-YYYY').format("DDMMYYYY"),
		"fechaTomaFin": isEmpty(values) ? moment(new Date()).format("DDMMYYYY") : moment(values.fechaTomaFin, 'DD-MM-YYYY').format("DDMMYYYY"),
		"c_comuna": isEmpty(values) ? null : values.c_comuna,
		"camion": isEmpty(values) ? null : values.camion,
		"clse_sector": isEmpty(values) ? null : values.clse_sector
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			//body: raw
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsTransmision,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los pedidos`
		}
	}
}

const columnsTransmision = [
	{ field: 'pedidoid', headerName: 'Pedido', width: 130 },
	{ field: 'estado', headerName: 'Estado', width: 140 },
	{ field: 'fechatoma', headerName: 'Fecha y hora', width: 180 },
	{ field: 'nombrecliente', headerName: 'Nombre Cliente', width: 180 },
	{ field: 'telefono', headerName: 'Télefono', width: 140 },
	{ field: 'comunaid', headerName: 'ComID', width: 140 },
	{ field: 'comuna', headerName: 'Comuna', width: 140 },
	{ field: 'calle', headerName: 'Calle', width: 200 },
	{ field: 'numero', headerName: 'Número', width: 140 },
	{ field: 'depto', headerName: 'Depto', width: 120 },
	{ field: 'casa', headerName: 'Casa', width: 120 },
	{ field: 'block', headerName: 'Block', width: 120 },
	{ field: 'referencia', headerName: 'Referencia', width: 200 },
	{ field: 'cantprod', headerName: 'Detalle', width: 600 },
	{ field: 'camion', headerName: 'Camion', width: 140 },
	/*{ field: 'fechaToma', headerName: 'Fecha de Toma', width: 200,
		renderCell: (params) => 
		(
			`${ moment(params.getValue(params.id, 'fechaToma'), 'YYYY-MM-DD HH:mm:ss').format("DD/MM/YYYY") }`
		)
	},*/
	{ field: 'censal', headerName: 'Censal', width: 140 },
	{ field: 'observacion', headerName: 'Observacion', width: 400 },
	{ field: 'usuario', headerName: 'Usuario', width: 140 },
	
	//{ field: 'detalle', headerName: 'Detalle', width: 300 },
]

export const getCentrosSap = async() => {
	//const url = `${ apiUrl }/estado`
	const url = `${ apiLogisticaUrl }/censal/centroSap`
	//myHeaders.set('Authorization', `Bearer ${ token }`)
    
	try {
		const resp = await fetch( url, {
			method: 'get', 
			headers: myHeaders, 
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()
            
			return {
				centrosSap: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los centros sap`
		}
	}
}