//const apiUrl = process.env.REACT_APP_API_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')
//const token = localStorage.getItem('token');
const apiRestUrl = process.env.REACT_APP_API_REST_URL

export const getProductosPreciosByComuna = async(idComuna) => {
	const url = `${apiRestUrl}/producto`
	//const url = `${ apiUrl }/producto?idComuna=${ idComuna }`
	//myHeaders.set('Authorization', `Bearer ${ token }`)

	const raw = {
		"idComuna": idComuna
	};
    
	try {
		/*const resp = await fetch( url, {
			method: 'get', 
			headers: myHeaders, 
		})*/
		const resp = await fetch( url, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(raw),
            redirect: 'follow'
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const data = await resp.json()
            
			return {
				rows: data.data,
                columns: columnsProductoPrecio,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las comunas`
		}
	}
}

export const getProductosDescuentos = async() => {
	const url = `${apiRestUrl}/producto/descuentos`
	//const url = `${ apiUrl }/producto/descuentos`
	//myHeaders.set('Authorization', `Bearer ${ token }`)
    
	try {
		const resp = await fetch( url, {
			method: 'get', 
			headers: myHeaders, 
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const data = await resp.json()
            
			return {
				rows: data.data,
                columns: columnsProductoDescuento,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las comunas`
		}
	}
}

export const updateDescuentos = async(values) => {
	const url = `${apiRestUrl}/producto/descuentos`
	//const url = `${ apiUrl }/producto/descuentos`
	//myHeaders.set('Authorization', `Bearer ${ token }`)
  
    try {
        const resp = await fetch( url, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(values),
            redirect: 'follow'
		})
        
        if(resp.status === 200 || resp.status === 201 || resp.status === 204) {
            //const { mensajeRespuesta } = await resp.json()
			const { mensaje_usr, mensaje_sis} = await resp.json()
			return {
			    message: mensaje_usr,
				status: mensaje_sis
			}
		} else {
            return {
				status: 'NOK',
			    message: "Ocurrió un error al procesar la solicitud"
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo actualizar los descuentos`
		}
	}
}


const columnsProductoPrecio = [
	{ field: 'producto', headerName: 'Producto', width: 280, editable: false, filterable: false, headerClassName: 'super-app-theme--header', headerAlign: 'left',
		renderCell: (params) => 
			(
				`${params.getValue(params.id, 'producto') } - ${params.getValue(params.id, 'descripcion')}`
			)
	},
	{ field: 'descripcion', headerName: 'Descripcion', width: 200, type: 'number', hide: true, headerClassName: 'super-app-theme--header', headerAlign: 'left', flex: 1  },
	{ field: 'cantidad', headerName: 'Cantidad', type: 'number', width: 110, editable: true, headerClassName: 'super-app-theme--header', headerAlign: 'left', flex: 1 },
	{ field: 'precio', headerName: 'Precio', width: 120, type: 'number', editable: false, headerClassName: 'super-app-theme--header', headerAlign: 'left', flex: 1 },
	{ field: 'cantidadGarantia', headerName: '# Garantia', type: 'number', width: 140, editable: true, headerClassName: 'super-app-theme--header', headerAlign: 'left', flex: 1 },
	{ field: 'precioGarantia', headerName: '$ Garantia', width: 140, type: 'number', editable: false, headerClassName: 'super-app-theme--header', headerAlign: 'left', flex: 1 },
	{ field: 'garantia', headerName: 'Garantia', type: 'number', width: 140, hide: true, headerClassName: 'super-app-theme--header', headerAlign: 'left', flex: 1 },
	{ field: 'descuento', headerName: 'Descuento', type: 'number', width: 140, editable: false, headerClassName: 'super-app-theme--header', headerAlign: 'left', flex: 1 },
	{ field: 'total', headerName: 'Total', type: 'number', width: 150, headerClassName: 'super-app-theme--header', headerAlign: 'left', flex: 1 }
]

const columnsProductoDescuento = [
	{ field: 'producto', headerName: 'Producto', width: 500, editable: false, filterable: false, headerClassName: 'super-app-theme--header', headerAlign: 'left',
		renderCell: (params) => 
			(
				`${params.getValue(params.id, 'producto') } - ${params.getValue(params.id, 'descripcion')}`
			)
	},
	{ field: 'descuento', headerName: 'Descuento', type: 'number', width: 40, editable: true, headerClassName: 'super-app-theme--header', headerAlign: 'left', flex: 1 }
]

