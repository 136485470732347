import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import DataGridNoRows from '../../common/DataGridNoRows';

export const TableBase = ({ getData, data = [] }) => {
  const { columns } = getData;

  const columnsWithCustomRendering = columns.map(column => {  
    if (column.field === 'tipo') {
      return {
        ...column,
        valueGetter: params => (params.row.tipo === '1' ? 'ET' : params.row.tipo === '2' ? 'SERV' : ''),
      };
    }
    return column;
  });

  const localizedTextsMap = {
    columnMenuUnsort: 'Sin orden',
    columnMenuSortAsc: 'Orden ascendente',
    columnMenuSortDesc: 'Orden desendente',
    columnMenuFilter: 'Filtro',
    columnMenuHideColumn: 'Ocultar',
    columnMenuShowColumns: 'Mostrar columnas',
  };

  return (
    <div style={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columnsWithCustomRendering}
        hideFooter={true}
        components={{
          NoRowsOverlay: () => DataGridNoRows('no-data'),
        }}
        localeText={localizedTextsMap}
      />
      <div
        style={{
          paddingTop: 30,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <span>
          Esta grilla solo permite visualizar los primeros 100 registros, al momento de la exportación se verán todos los datos en el archivo CSV
        </span>
      </div>
    </div>
  );
};
