import React from 'react'
import CsvDownloader from 'react-csv-downloader'
import { Tooltip, IconButton } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'

const processHeader = (headerData, dataCsv) => {
    return headerData.map(header => 
        (
            {
                id: header.field,
                displayName: header.headerName
            }
        )
    )
} 

export const CsvExportBase = ({ header, data=[], title }) => {
    return (
        <div>
            <CsvDownloader 
                columns={ processHeader(header) }
                datas={ data } 
                filename={ `Export${ title }`+new Date().getTime() }
                extension=".csv"
                separator=";"
                text={
                        <Tooltip title={`Exportar a CSV`}>
                            <IconButton aria-label="delete" >
                                <GetAppIcon style={{ color: '#36B37E' }}  />
                            </IconButton>
                        </Tooltip>      
                } 
                style={{ borderWidth: 0, width: 60, backgroundColor: 'white' }}
            />
        </div>
    )
}
