//const apiUrlSllMap = process.env.REACT_APP_API_URL_SLL_MAP
const apiDireccionesUrl = process.env.REACT_APP_API_DIRECCIONES_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

//const token = localStorage.getItem('token');

export const getCallesCallejero = async(idComuna, calle) => {
	//const url = `${ apiUrlSllMap }/calle/buscarCalle?calle=${calle}&cantidadRegistros=6&codigoComuna=${idComuna}&idUsuario=0&codPais=cl`
	const url = `${ apiDireccionesUrl }/calle`

	const raw = {
		"filter": calle,
		"comuna": idComuna,
		"cantidad": 6
	};

	try {
		const resp = await fetch( url, {
			//method: 'get', 
			//headers: myHeaders, 
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 204) {
			//const { calles } = await resp.json()
			const data = await resp.json()

            return {
				//calles,
				calles: data.data,
                status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las calles`
		}
	}
}
