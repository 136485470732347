import React, { useEffect } from 'react' //, useState, useContext
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export const FormBase = React.memo(({ estadoTransmitir, estadoTomadoAEnTrans, handleChange }) => {
    useEffect(() => {
    }, [])

    return (
        <div>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={estadoTomadoAEnTrans}
                            onChange={handleChange}
                            name="postEstadoTomadoAEnTransmisionONOFF"
                            color="primary"
                        />
                    }
                    label="Cambiar estado de TOMADO a EN TRANSMISION"
                />
            </FormGroup>
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Switch
                            checked={estadoTransmitir}
                            onChange={handleChange}
                            name="postEstadoTransmisionONOFF"
                            color="primary"
                        />
                    }
                    label="Transmitir automáticamente los pedidos EN TRANSMISION"
                />
            </FormGroup>
        </div>
    )
})