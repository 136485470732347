import React from 'react';
import Button from '@material-ui/core/Button';
import { styles as dialogStyles } from '../styles/dialogStyles'

export const ButtonControl = ({ onClick, label, type, disabled=false }) => {
    return (
        <div>
            { type === "primary" 
            ?
                <Button 
                    style={ disabled ? dialogStyles.popUpButtonDisabled : dialogStyles.popUpButtonAceptar }
                    variant="outlined" 
                    color= { type }
                    type="submit"
                    disabled={ disabled }
                    >
                    { label }
                    
                </Button>
            : type === "secondary"
                ?
                    <Button 
                        style={ disabled ? dialogStyles.popUpButtonDisabled : dialogStyles.popUpButtonAceptar }
                        variant="contained" 
                        onClick={ onClick }
                        color= { type }
                        type= { type }
                        disabled={ disabled }
                        >
                        { label }
                    </Button>
                :
                <Button 
                    style={ disabled ? dialogStyles.popUpButtonDisabled : dialogStyles.popUpButtonAceptar } 
                    variant="outlined" 
                    color= "primary"
                    onClick={ onClick }
                    type= { type }
                    disabled={ disabled }
                    >
                    { label }
                </Button>
            }
        </div>
    )
}
