import React, { useState, useEffect, useContext } from 'react'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
//import { getTiposanotAll } from '../../helpers/tiposanot/apiCalls'
//import { getCamionesAll } from '../../helpers/camion/apiCalls'
import { activarCamiones, desactivarCamiones } from '../../helpers/moviles/apiCalls'
import { GeneralContext } from '../../context/GeneralContext'
import { DialogAlert } from '../base/DialogAlert'
import Spinner from '../base/Spinner'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '50ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textFieldCam: {
        marginTop: 58,
        marginLeft: 20,
        minWidth: 260,
    },
    textFieldObs: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '50ch',
        marginLeft: 12,
    },
}));

export const ActionBase = ({ setReload, reload }) => {

    const classes = useStyles();

    const { stateGeneral, setStateGeneral } = useContext(GeneralContext)
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    useEffect(() => {
        let isMounted = true
        setOpenSpinner(true)
        setOpenSpinner(false)
        return () => { isMounted = false }
    }, [reload])

    const cleanData = () => {
        setStateGeneral({ ...stateGeneral })
        setReload(new Date())
    }

    const onHandleSubmitActivar = e => {
        e.preventDefault()

        let camiones = [];
        camiones = localStorage.getItem('camionesList');
        let camionesLength = 0;

        let camionesArray = [];
        camionesArray = camiones.split(",");

        if (camionesArray === '') {
            camionesLength = 0;
        } else {
            camionesLength = camionesArray.length;
        }

        if (camionesLength === 0) {
            setMessage("Debe seleccionar los camiones para poder activarlos...")
            setCodeAlert(false)
            setOpenAlert(true)
            return
        } else {
            setOpenSpinner(true)
            activarCamiones(camiones)
                .then(({ message, status }) => {
                    setMessage(message === "" ? "Error al activar" : message)
                    setCodeAlert(status === "OK")
                    setOpenAlert(true)
                    cleanData()
                    setOpenSpinner(false)
                })
        }
    }

    const onHandleSubmitDesactivar = e => {
        e.preventDefault()

        let camiones = [];
        camiones = localStorage.getItem('camionesList');
        let camionesLength = 0;

        let camionesArray = [];
        camionesArray = camiones.split(",");

        if (camionesArray === '') {
            camionesLength = 0;
        } else {
            camionesLength = camionesArray.length;
        }

        if (camionesLength === 0) {
            setMessage("Debe seleccionar los camiones para poder desactivarlos...")
            setCodeAlert(false)
            setOpenAlert(true)
            return
        } else {
            setOpenSpinner(true)
            desactivarCamiones(camiones)
                .then(({ message, status }) => {
                    setMessage(message === "" ? "Error al desactivar" : message)
                    setCodeAlert(status === "OK")
                    setOpenAlert(true)
                    cleanData()
                    setOpenSpinner(false)
                })
        }
    }

    return (
        <div className={classes.root}>
            <Grid item xs={12} style={{ marginTop: 30, marginBottom: 30 }} container justifycontent="center">
                <Grid item xs={2}>
                    <Divider orientation="vertical"></Divider>
                </Grid>
                <Grid item xs={2} style={{ marginRight: 20, marginLeft: -100 }}>
                    <form onSubmit={onHandleSubmitActivar} >
                        <Grid container justifycontent="center" spacing={1}>
                            <Grid item style={{ marginTop: 58 }}>
                                <ButtonControl
                                    label="Activar"
                                    type="primary"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={2}>
                    <Divider orientation="vertical"></Divider>
                </Grid>
                <Grid item xs={2} style={{ marginRight: 20, marginLeft: -100 }}>
                    <form onSubmit={onHandleSubmitDesactivar} >
                        <Grid container justifycontent="center" spacing={1}>
                            <Grid item style={{ marginTop: 58 }}>
                                <ButtonControl
                                    label="Desactivar"
                                    type="primary"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={2}>
                    <Divider orientation="vertical"></Divider>
                </Grid>

                

            </Grid>
            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />
        </div>
    )
}


