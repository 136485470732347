import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getSectorizacionByFilters } from '../../helpers/sectorizacion/apiCalls'
import { TableBase } from './TableBase'
import { FormBase } from './FormBase'
import Grid from '@material-ui/core/Grid';
import { GeneralContext } from '../../context/GeneralContext'
import { isEmpty } from 'lodash'
import Spinner from '../base/Spinner'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));

export const SectorizacionScreen = () => {
  const { stateGeneral } = useContext(GeneralContext)
  const classes = useStyles();
  const [data, setData] = useState({})
  const [reload, setReload] = useState('')
  const [openSpinner, setOpenSpinner] = useState(false)


  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      setOpenSpinner(true)
      getSectorizacionByFilters(isEmpty(stateGeneral.filtersExportSll_sectorizacion) ? {} : stateGeneral.filtersExportSll_sectorizacion)
        .then((result) => {
          setData(result)
          setOpenSpinner(false)
        })
    }
    fetchData()
    return () => { isMounted = false }
  }, [reload])


  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormBase
            setReload={setReload}
            reload={reload}
          />
        </Grid>

        {data && data.columns &&
          <Grid item xs={12}>
            <TableBase
              getData={data}
              data={data.rows}
            />
          </Grid>
        }
      </Grid>

      <Spinner open={openSpinner} />

    </div>
  )
}
