import React, { useEffect, useState } from 'react' //, useContext
import { postEstadoTransmisionONOFF, postEstadoTomadoAEnTransmisionONOFF, getEstadoONOFF } from '../../helpers/administrador/apiCalls'
import { FormBase } from './FormBase'
import Grid from '@material-ui/core/Grid';

export const AdministradorScreen = () => {

    const [reload, setReload] = useState('')

    const [estadoTransmitir, setEstadoTransmitir] = useState(false);
    const [estadoTomadoAEnTrans, setEstadoTomadoAEnTrans] = useState(false);

    const [count, setCount] = useState(0);

    useEffect(() => {
        let isMounted = true
        const fetchData = async () => {
            getEstadoONOFF("CONTIN_TRANSMISION", "ESTADO", "TRANSMITIR").then((result) => { setEstadoTransmitir(result.estado === 'ON' ? true : false) })
            getEstadoONOFF("CONTIN_TRANSMISION", "ESTADO", "TOMADO_A_ENTRANS").then((result) => { setEstadoTomadoAEnTrans(result.estado === 'ON' ? true : false) })
        }
        fetchData()
        return () => { isMounted = false }
    }, [reload])

    const handleChange = (event) => {
        console.log(event.target.name)
        console.log(event.target.checked)
        setCount(count + 1)
        if (event.target.name === 'postEstadoTransmisionONOFF') {
            setEstadoTransmitir(event.target.checked)
            if (event.target.checked === true) {
                postEstadoTransmisionONOFF(1)
                    .then((result) => {
                        console.log(result)
                    })
            } else {
                postEstadoTransmisionONOFF(0)
                    .then((result) => {
                        console.log(result)
                    })
            }

        } else {
            setEstadoTomadoAEnTrans(event.target.checked)
            if (event.target.checked === true) {
                postEstadoTomadoAEnTransmisionONOFF(1)
                    .then((result) => {
                        console.log(result)
                    })
            } else {
                postEstadoTomadoAEnTransmisionONOFF(0)
                    .then((result) => {
                        console.log(result)
                    })
            }
        }
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormBase
                        estadoTransmitir={estadoTransmitir}
                        estadoTomadoAEnTrans={estadoTomadoAEnTrans}
                        handleChange={handleChange}
                        count={count}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
