import React from 'react'; //, { useState, useContext }
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';
import { DataGrid } from '@material-ui/data-grid'
import { isEmpty } from 'lodash'
//import { GeneralContext } from '../../context/GeneralContext' 
//import { startOfYesterday } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .super-app-theme--header': {
            backgroundColor: '#FCDB00',
            fontSize: 13,
            fontWeight: 700
          },
         '& .MuiDataGrid-columnSeparator': {
            backgroundColor: '#FCDB00',
         } 
      },
      headerStyle: {
          height: 330,
      }
}));

export const TableBase = ({ getData, setStateGeneral, stateGeneral }) => {
    const classes = useStyles();
    const descuentos = stateGeneral.descuentos
    const { columns } = getData
    const { rows } = getData

    const handleCellEditCommit =({ id, field, props }) => {
        const { value } = props
          if (field === 'descuento') {
            const updatedRows = rows.find(row => row.id === id)
            if(!isEmpty(updatedRows)) {
                if(descuentos.length === 0) {
                    const newArray = [...descuentos, { ...updatedRows, descuento: value }]
                    setStateGeneral({ ...stateGeneral, descuentos: newArray })
                } else {
                    const updatedList = descuentos.map(item => {  
                        if(item.id === id) {
                            return {...item, descuento: value};
                        } 
                        return item
                    })
                    const existingRow = descuentos.find(row => row.id === id)
                    
                    if(isEmpty(existingRow)) {
                        updatedList.push({ ...updatedRows, descuento: value })
                    }
                    setStateGeneral({ ...stateGeneral, descuentos: updatedList })
                }
            }
          }
    }

    return (
         <div className={classes.root}>
            <Grid 
                container 
                spacing={3} 
            > 
                <Grid item xs={10} className={classes.headerStyle}>
                    <DataGrid  
                            rows={ rows }   
                            rowHeight={25}  
                            headerHeight={ 40 }
                            disableColumnMenu={true} 
                            columns={ columns } 
                            hideFooter= { true }
                            onEditCellPropsChange={ handleCellEditCommit }
                        />
                </Grid>
                
            </Grid>
        </div>
    )
}
