import { isEmpty } from 'lodash'
//const apiUrl = process.env.REACT_APP_API_URL
const apiLogisticaUrl = process.env.REACT_APP_API_LOGISTICA_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

const token = localStorage.getItem('token');

export const getCamionesAll = async (values) => {
	const url = `${apiLogisticaUrl}/camion`

	const raw = {
		"estado": isEmpty(values) ? null : values.estado,
		"oficina": isEmpty(values) ? null : values.oficina
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})
		if (resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()

			return {
				camiones: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los camiones`
		}
	}
}
