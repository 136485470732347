import React, { useState, useEffect, useContext } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { InputText } from '../base/controls/InputText'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip, IconButton } from '@material-ui/core'
import DeleteSweep from '@material-ui/icons/DeleteSweep'
import { styles as dialogStyles } from '../styles/dialogStyles'
import { getComunasAll } from '../../helpers/comuna/apiCalls'
import { addPedido } from '../../helpers/pedido/apiCalls'
import { getCallesCallejero } from '../../helpers/calle/apiCalls'
import { GeneralContext } from '../../context/GeneralContext'
import { UserContext } from '../../context/UserContext'
import { DialogAlert } from '../base/DialogAlert'
import Grid from '@material-ui/core/Grid';
import Spinner from '../base/Spinner'
import { isEmpty } from 'lodash'
import CircularProgress from "@material-ui/core/CircularProgress"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


export const FormBase = ({ setReload, reload }) => {
    const classes = useStyles();
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext)
    const { userContextState } = useContext(UserContext)
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [comunas, setComunas] = useState([])
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;


    const [values, setValues] = useState({
        c_comuna: 0,
        nombreCliente: '',
        telefono: '',
        calle: '',
        numero: '',
        depto: '',
        block: '',
        casa: '',
        referencia: '',
    })

    const handleCloseAlert = () => {
        if (openAlert) {
            setOpenAlert(false)
        }
    }

    useEffect(() => {
        let isMounted = true
        setStateGeneral({ ...stateGeneral, filters: values, observacion: '' })
        setOpenSpinner(true)
        if (comunas.length === 0) {
            getComunasAll()
                .then(({ comunas }) => {
                    if (isMounted) {
                        setComunas(comunas)
                    }
                })
        }
        setOpenSpinner(false)
        return () => { isMounted = false }
    }, [reload])

    const handleChangeForm = (name, event) => {
        if (name == 'c_comuna') {
            setValues({ ...values, [name]: event })
            setStateGeneral({ ...stateGeneral, comunaSll: event })
            setReload(new Date())
        } else {
            setValues({ ...values, [name]: event.target.value })
            setStateGeneral({ ...stateGeneral, filters: [values] })
        }
    }

    const [selectedValue, setSelectedValue] = useState(null);
    const handleInputChange = (obj, value) => {
        setSelectedValue({ ...values, ['calle']: value });
        setValues({ ...values, ['calle']: value });
    };

    const cleanData = () => {
        setValues({
            c_comuna: 0,
            nombreCliente: '',
            telefono: '',
            calle: 0,
            numero: '',
            depto: '',
            block: '',
            casa: '',
            referencia: '',
        })
        setStateGeneral({ ...stateGeneral, pedido: [], totales: {}, comunaSll: 0, observacion: '' })
        setComunas([])
        setSelectedValue(null);
        setReload(new Date())
    }

    const onHandleSubmit = e => {
        e.preventDefault()
        const { pedido, comunaSll } = stateGeneral
        
        let validaCantidad = 0
        if(pedido && pedido.length > 0) {
            validaCantidad = pedido.filter(x => x.cantidad > 0)
        }

        if (comunaSll === 0) {
            setMessage("Debe seleccionar una comuna para generar el pedido...")
            setCodeAlert(false)
            setOpenAlert(true)
            return
        }
        if (!validaCantidad.length > 0) {
            setMessage("Debe agregar al menos un producto al pedido...")
            setCodeAlert(false)
            setOpenAlert(true)
            return
        }
        setOpenSpinner(true)
        addPedido({
            estado: 1,
            cliente: 0,
            nombreCliente: values.nombreCliente,
            usuario: localStorage.getItem("usuario"),
            fechaEntrega: new Date(),
            comunaId: values.c_comuna,
            calle: values.calle,
            numero: values.numero,
            casa: values.casa,
            depto: values.depto,
            block: values.block,
            referencia: values.referencia,
            montoDescuento: stateGeneral.totales.descuento,
            montoTotal: stateGeneral.totales.total,
            camion: values.camion,
            telefono: values.telefono,
            observacion: stateGeneral.observacion,
            detallePedido: pedido.filter(x => x.cantidad > 0 && {
                pedidoId: 0,
                producto: x.producto,
                garantia: x.garantia,
                cantidad: x.cantidad,
                cantidadGarantia: x.cantidadGarantia
            })
        })
            .then(({ message, status }) => {
                setMessage(message === "" ? "Error al crear el pedido" : message)
                setCodeAlert(status === "OK")
                setOpenAlert(true)
                cleanData()
                setOpenSpinner(false)
            })
    }

    const onChangeHandle = async value => {
        const { calles } = await getCallesCallejero(values.c_comuna, value).then((data) => { return data })
        if (calles && calles.length > 0) {
            setOptions(calles)
        } else {
            setOptions([{ idCalle: 0, calle: value }])
        }
    };

    return (

        <div className={classes.root}>
            <form
                onSubmit={onHandleSubmit}
            >
                <Grid
                    container
                    spacing={3}
                    style={{
                        backgroundColor: 'white',
                        padding: 20
                    }}
                >
                    <Grid item xs={9}>
                        <Grid justifycontent="left" spacing={1}>
                            <Grid container justifycontent="left" spacing={1}>
                                <Grid item>
                                    <InputText
                                        name="nombreCliente"
                                        label="Nombre Cliente"
                                        required={true}
                                        type="text"
                                        value={values.nombreCliente}
                                        onChangeFunction={(e) => handleChangeForm("nombreCliente", e)}
                                        lengthMax={100}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="telefono"
                                        label="Teléfono"
                                        required={true}
                                        type="number"
                                        value={values.telefono}
                                        lengthMax={10}
                                        lengthMin={9}
                                        onChangeFunction={(e) => handleChangeForm("telefono", e)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifycontent="left" spacing={1}>
                                <Grid item>
                                    {comunas && comunas.length > 0 &&
                                        <Autocomplete
                                            id="size-small-outlined"
                                            size="small"
                                            className={classes.formControl}
                                            options={comunas}
                                            getOptionLabel={(option) => `${option.c_comuna} - ${option.comunaNombre}`}
                                            defaultValue={null}
                                            onChange={(event, newValue) => {
                                                if (!isEmpty(newValue)) {
                                                    handleChangeForm("c_comuna", newValue.c_comuna)
                                                } else {
                                                    handleChangeForm("c_comuna", null)
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Comuna" placeholder="Seleccione Comuna" />
                                            )}
                                        />
                                    }
                                </Grid>
                                <Grid item>
                                    <Autocomplete
                                        value={selectedValue}
                                        id="calle_select"
                                        className={classes.formControl}
                                        size="small"
                                        style={{ width: 500 }}
                                        open={open}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        getOptionSelected={(option, value) => option.calle === value.calle}
                                        getOptionLabel={option => option.calle}
                                        onInputChange={handleInputChange}
                                        options={options}
                                        loading={loading}
                                        autoSelect={false}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Calles"
                                                variant="outlined"
                                                onChange={ev => {
                                                    // dont fire API if the user delete or not entered anything
                                                    if (ev.target.value !== "" || ev.target.value !== null) {
                                                        onChangeHandle(ev.target.value);
                                                    }
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loading ? (
                                                                <CircularProgress color="inherit" size={20} />
                                                            ) : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="numero"
                                        label="Número"
                                        required={true}
                                        type="text"
                                        value={values.numero}
                                        onChangeFunction={(e) => handleChangeForm("numero", e)}
                                        lengthMax={8}
                                        widthStyle='small'
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifycontent="left" spacing={1}>
                                <Grid item>
                                    <InputText
                                        name="depto"
                                        label="Depto"
                                        required={false}
                                        type="text"
                                        value={values.depto}
                                        onChangeFunction={(e) => handleChangeForm("depto", e)}
                                        lengthMax={100}
                                        widthStyle='small'
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="block"
                                        label="Block"
                                        required={false}
                                        type="text"
                                        value={values.block}
                                        onChangeFunction={(e) => handleChangeForm("block", e)}
                                        lengthMax={100}
                                        widthStyle='small'
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="casa"
                                        label="Casa"
                                        required={false}
                                        type="text"
                                        value={values.casa}
                                        onChangeFunction={(e) => handleChangeForm("casa", e)}
                                        lengthMax={100}
                                        widthStyle='small'
                                    />
                                </Grid>
                                <Grid item>
                                    <InputText
                                        name="referencia"
                                        label="Referencia"
                                        required={false}
                                        type="text"
                                        value={values.referencia}
                                        onChangeFunction={(e) => handleChangeForm("referencia", e)}
                                        lengthMax={100}
                                        widthStyle='long'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} container spacing={1} direction="row" style={{ alignItems: 'flex-end', flexDirection: 'row-reverse' }}>
                        <Grid item style={{ marginTop: 8 }}>
                            <ButtonControl
                                label="Crear Pedido"
                                type="primary"
                            />
                        </Grid>
                        <Grid item style={{ marginTop: 8 }}>
                            <Tooltip title={`Limpiar`}>
                                <IconButton
                                    aria-label="Filter list"
                                    onClick={cleanData}
                                    style={dialogStyles.cleanFilters}
                                >
                                    <DeleteSweep
                                        fontSize={'large'}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </form>

            {openAlert &&
                <DialogAlert
                    title="Alerta"
                    message={message}
                    type="alert"
                    code={codeAlert}
                    method="DELETE"
                    openAlert={openAlert}
                    handleCloseAlert={handleCloseAlert}
                />
            }
            <Spinner open={openSpinner} />

        </div>
    )
}