import React from 'react';
import CsvDownloader from 'react-csv-downloader';
import { Tooltip, IconButton } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';

const processHeader = (headerData, dataCsv) => {
  return headerData.map(header => {
    if (header.field === 'tipo') {
      return {
        id: header.field,
        displayName: header.headerName,
        accessor: row => (row.tipo === '1' ? 'ET' : 'SERV'),
      };
    }
    return {
      id: header.field,
      displayName: header.headerName,
      accessor: header.field, // Usar el campo original para las demás columnas
    };
  });
};

export const CsvExportBaseEmer = ({ header, data = [], title }) => {
  const processedData = data.map(row => {
    const processedRow = { ...row };
    if (row.tipo === '1') {
      processedRow.tipo = 'ET';
    } else if (row.tipo === '2') {
      processedRow.tipo = 'SERV';
    }
    return processedRow;
  });

  return (
    <div>
      <CsvDownloader
        columns={processHeader(header, processedData)}
        datas={processedData}
        filename={`Export${title}` + new Date().getTime()}
        extension=".csv"
        separator=";"
        text={
          <Tooltip title={`Exportar a CSV`}>
            <IconButton color="secondary">
              <CloudDownloadIcon style={{ color: '#36B37E' }} />
              <Typography
                component={'span'}
                style={{ paddingLeft: 10, color: '#36B37E' }}
              >
                Exportar a CSV
              </Typography>
            </IconButton>
          </Tooltip>
        }
        style={{ borderWidth: 0, width: 200, backgroundColor: 'white' }}
      />
    </div>
  );
};
