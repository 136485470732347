import React, {useState, useEffect, useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { getEmergenciasByFilters } from '../../helpers/emergencias/apiCalls'
import { TableBase } from './TableBase'
import { ExcelExportBaseEmer } from '../base/ExcelExportBaseEmer'
import { CsvExportBaseEmer } from '../base/CsvExportBaseEmer'
import { FormBase } from './FormBase'
import Grid from '@material-ui/core/Grid';
import { GeneralContext } from '../../context/GeneralContext' 
import { isEmpty } from 'lodash'
import Spinner from '../base/Spinner'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));

export const GestionSLLEmergenciasScreen = () => {
  const { stateGeneral  } = useContext(GeneralContext)
  const classes = useStyles();
  const [data, setData] = useState({})
  const [reload, setReload] = useState('')
  const [openSpinner, setOpenSpinner] = useState(false)
  
  
  useEffect(() => {
    let isMounted = true
    const fetchData =  async () => {
        setOpenSpinner(true)
        getEmergenciasByFilters(isEmpty(stateGeneral.filtersExportSll_emergencia) ? {} : stateGeneral.filtersExportSll_emergencia)
            .then(( result ) => {
                setData(result)
                setOpenSpinner(false)
            })
    }
    fetchData()
    return () => { isMounted = false }
  }, [reload])


    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormBase
              setReload = { setReload }
              reload = { reload }
            />
          </Grid>  
          { data && data.columns &&
              <Grid container item>
                <Grid item style={{ backgroundColor: 'white' }}>
                  <CsvExportBaseEmer
                      header={ data.columns }
                      data={ data.rows }
                      title={ '_emergencias_' }
                  />
                </Grid>
              </Grid>
          }
          
          { data && data.columns &&
            <Grid item xs={12}>
              <TableBase 
                        getData={ data }
                        data={ data.rows } 
                />
            </Grid>
          }
        </Grid>

        <Spinner open={ openSpinner }/>
        
      </div>
    )
}
