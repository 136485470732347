import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography';
import { PopUpBase } from '../base/PopUpBase'
import { ButtonControl } from '../../components/base/ButonControl'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseIcon from '@material-ui/icons/Close';

const styles = {
    largeIcon: {
        fontSize: 50,
        fill: "#36b37e",
        backgroundColor: '#d0e8d6',
        borderRadius: 100,
    },
    largeIconError: {
        fontSize: 50,
        fill: '#FF624E',
        backgroundColor: '#ffcccc',
        borderRadius: 100,
    },
    fontTitle: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 700,
        fontSize: 15
    },
    fontMessage: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 400,
        fontSize: 13
    },
    fontTitleError: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 700,
        fontSize: 15,
        color: '#FF624E'
    },
}


export const DialogAlert = ({ title, message, type, code, openAlert, handleCloseAlert }) => {
    const [openPopUp, setOpenPopUp] = useState(false)
    const getAlertByCode = () => {
        return(
            <div style={{ textAlign: 'center', minWidth: 250 }}>
            { code 
                ? 
                <>
                <CheckRoundedIcon  
                    style={ styles.largeIcon }
                />
                <Typography component={'span'}>
                    <p style={ styles.fontTitle }>Listo!</p>
                </Typography>
                <Typography component={'span'}>
                    <p style={ styles.fontMessage }>{ message }</p>
                </Typography>
                <ButtonControl
                    onClick={ handleCloseAlert } 
                    label="Volver"
                    type="secondary"
                />
                </>
                :
                <>
                <CloseIcon  
                    style={ styles.largeIconError }
                    />
                    <Typography component={'span'}>
                        <p style={ styles.fontTitleError }>Error!</p>
                    </Typography>
                    <Typography component={'span'}>
                        <p style={ styles.fontMessage }>{ message }</p>
                    </Typography>
                    <ButtonControl
                        onClick={ handleCloseAlert } 
                        label="Cerrar"
                        type="secondary"
                    />
                </>
            }
            </div>
        ) 
     }
    return (
        <div style={{ textAlign: 'center' }}>
            <PopUpBase
                title= { title }
                open= { openAlert }
                children= {  getAlertByCode() }
                type={ type }
                validaWidth={ false }
                maximoW={"sm"}
                setOpenPopUp={ setOpenPopUp }
            />
        </div>
    )
}
