import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textFieldSmall: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '11ch',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '30ch',
  },
  textFieldLong: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '50ch',
  },
}));

export const InputText = ({
  defaultValue,
  label,
  required,
  type,
  descripcion = "",
  onChangeFunction,
  value,
  lengthMax,
  lengthMin = 0,
  disabled = false,
  widthStyle }) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      {type === "text" &&
        <TextField
          defaultValue={defaultValue}
          label={label}
          required={required}
          id="outlined-margin-dense"
          className={widthStyle === "small"
            ? classes.textFieldSmall
            : widthStyle === "long"
              ? classes.textFieldLong
              : classes.textField}
          helperText={descripcion}
          margin="dense"
          variant="outlined"
          onChange={e => onChangeFunction(e)}
          value={value}
          inputProps={{ maxLength: lengthMax }}
          disabled={disabled}
          size={'medium'}
          type={type}
        />
      }
      {type === "password" &&
        <TextField
          defaultValue={defaultValue}
          label={label}
          required={required}
          id="outlined-margin-dense"
          className={
            widthStyle === 'small'
              ? classes.textFieldSmall
              : widthStyle === 'long'
                ? classes.textFieldLong
                : classes.textField
          }
          helperText={descripcion}
          margin="dense"
          variant="outlined"
          onChange={(e) => onChangeFunction(e)}
          value={value}
          inputProps={{ maxLength: lengthMax }}
          disabled={disabled}
          size="medium"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      }
      {type === "number" &&
        <TextField
          defaultValue={defaultValue}
          label={label}
          required={required}
          id="outlined-margin-dense"
          className={widthStyle === "small"
            ? classes.textFieldSmall
            : widthStyle === "long"
              ? classes.textFieldLong
              : classes.textField}
          helperText={descripcion}
          margin="dense"
          variant="outlined"
          onChange={e => onChangeFunction(e)}
          value={value}
          inputProps={{ maxLength: lengthMax }}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
          }}
          disabled={disabled}
          size={'medium'}
          type={"text"}
        />
      }
      {type !== "number" && type !== "text" && type !== "password" &&
        <TextField
          defaultValue={defaultValue}
          id="outlined-full-width"
          label={label}
          className={classes.textFieldLong}
          required={required}
          style={{ margin: 8 }}
          placeholder={label}
          helperText={descripcion}
          fullWidth
          multiline={true}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          onChange={e => onChangeFunction(e)}
          value={value}
          inputProps={{ maxLength: 3 }}
          disabled={disabled}
          type={type}
        />
      }
    </div>
  )
}
