//const apiUrl = process.env.REACT_APP_API_URL
const apiDireccionesUrl = process.env.REACT_APP_API_DIRECCIONES_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

//const token = localStorage.getItem('token');

export const getComunasAll = async() => {
	//const url = `${ apiUrl }/comuna`
	const url = `${ apiDireccionesUrl }/comuna`
	//myHeaders.set('Authorization', `Bearer ${ token }`)
    
	try {
		const resp = await fetch( url, {
			method: 'get', 
			headers: myHeaders, 
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const data = await resp.json()

			delete data.data[0];

			return {
				comunas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para obtener las comunas`
		}
	}
}
