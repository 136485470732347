import { isEmpty } from 'lodash'
const apiLogisticaUrl = process.env.REACT_APP_API_LOGISTICA_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

export const getMovilesByFilters = async (values) => {
	const url = `${apiLogisticaUrl}/camion`

	const raw = {
		"estado": isEmpty(values) ? null : values.estado,
		"oficina": isEmpty(values) ? null : values.oficina
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})

		if (resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()

			return {
				rows: data.data,
				columns: columnsMoviles,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch (err) {
		return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los pedidos`
		}
	}
}

const columnsMoviles = [
	{ field: 'camion', headerName: 'Camion', width: 130 },
	{ field: 'transportista', headerName: 'Transportista', width: 300 },
	{ field: 'chofer', headerName: 'Chofer', width: 300 },
	{ field: 'terminal', headerName: 'Terminal', width: 140 },
	{ field: 'oficina', headerName: 'Oficina', width: 140 },
	{ field: 'telefono', headerName: 'Telefono', width: 140, editable: true },
	{ field: 'modo_operacion', headerName: 'Modo operacion', width: 200 },
	{ field: 'clcl_id', headerName: 'ID Cluster', width: 140 },
	{ field: 'clcl_nombre', headerName: 'Nombre Cluster', width: 300 }
]

export const getOficinasAll = async() => {
	const url = `${ apiLogisticaUrl }/oficina`
	//myHeaders.set('Authorization', `Bearer ${ token }`)
    
	try {
		const resp = await fetch( url, {
			method: 'get', 
			headers: myHeaders, 
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()
            
			return {
				oficinas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los oficinas`
		}
	}
}

export const updateFonoCamion = async(values) => {
	const url = `${ apiLogisticaUrl }/camion/fonoCamion`
	//myHeaders.set('Authorization', `Bearer ${ token }`)

	
	const raw = {
		"fono": values.value,
		"camion": values.id,
		"usuario": localStorage.getItem('usuario')
	};
	

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()
            
			return {
				oficinas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para actualizar el teléfono del camión`
		}
	}
}

export const activarCamiones = async(camiones) => {
	const url = `${ apiLogisticaUrl }/camion/activar`
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	
	const raw = {
		"camiones": camiones,
		"usuario": localStorage.getItem('usuario')
	};
	
	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()
            
			return {
				oficinas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para activar los camiones`
		}
	}
}

export const desactivarCamiones = async(camiones) => {
	const url = `${ apiLogisticaUrl }/camion/desactivar`
	//myHeaders.set('Authorization', `Bearer ${ token }`)
	
	const raw = {
		"camiones": camiones,
		"usuario": localStorage.getItem('usuario')
	};

	try {
		const resp = await fetch(url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()
            
			return {
				oficinas: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para activar los camiones`
		}
	}
}