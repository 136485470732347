import React, { useState } from 'react'
import DataGridNoRows from '../../common/DataGridNoRows'
import { DataGrid } from '@material-ui/data-grid'
import { updateFonoCamion } from '../../helpers/moviles/apiCalls'

export const TableBase = ({ getData, data = [] }) => {
    const { columns } = getData

    const updateFono = (v) => {
        updateFonoCamion(v)
    }

    const localizedTextsMap = {
        columnMenuUnsort: "Sin orden",
        columnMenuSortAsc: "Orden ascendente",
        columnMenuSortDesc: "Orden desendente",
        columnMenuFilter: "Filtro",
        columnMenuHideColumn: "Ocultar",
        columnMenuShowColumns: "Mostrar columnas"
    }

    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                checkboxSelection={true}
                hideFooter={false}
                components={{
                    NoRowsOverlay: () => DataGridNoRows("no-data"),
                }}
                onCellEditCommit={v => updateFono(v)}
                onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRowData = data.filter((row) => selectedIDs.has(row.id.toString()));

                    if (selectedRowData && selectedRowData.length > 0) {
                        const camiones = new Array();
                        for(let i = 0; i < selectedRowData.length; i++) {
                            camiones.push(selectedRowData[i].camion)
                        }
                        localStorage.setItem('camionesList', camiones);
                    }
                }}
                localeText={localizedTextsMap}
            />
            <div style={{
                paddingTop: 30,
                backgroundColor:
                    'white',
                display: 'flex',
                flexDirection: 'row-reverse'
            }}
            >
            </div>
        </div>
    )
}