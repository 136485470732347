import React from 'react';
import ReactExport from 'react-export-excel';
import { Tooltip, IconButton } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Typography from '@material-ui/core/Typography';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelSheet;
const ExcelColumn = ReactExport.ExcelColumn;

const removeId = data => data.filter(field => field.id !== "id");

export const ExcelExportBaseEmer = ({ header, data = [], title }) => {
  const processedData = data.map(row => {
    const processedRow = { ...row };
    if (row.tipo === '1') {
      processedRow.tipo = 'ET';
    } else if (row.tipo === '2') {
      processedRow.tipo = 'SERV';
    }
    return processedRow;
  });

  return (
    <div>
      <ExcelFile
        element={
          <Tooltip title={`Exportar a Excel`}>
            <IconButton color="secondary">
              <CloudDownloadIcon style={{ color: '#36B37E' }} />
              <Typography
                component={'span'}
                style={{ paddingLeft: 10, color: '#36B37E' }}
              >
                Exportar
              </Typography>
            </IconButton>
          </Tooltip>
        }
        filename={`Export${title}` + new Date().getTime()}
      >
        <ExcelSheet data={removeId(processedData)} name="Nombre Data">
          {removeId(header).map(element => (
            <ExcelColumn label={element.headerName} value={element.field} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
};
