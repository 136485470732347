import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { 
    Dialog, 
    DialogContent, 
} 
from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export const PopUpBase = ({ title, open, children, type,  validaWidth, maximoW,  setOpenPopUp={ setOpenPopUp }}) => {
  const handleClose = () => {
    setOpenPopUp(false)
   }
    return (
      <div>
        <Dialog
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          fullWidth={validaWidth}
          maxWidth={maximoW}
          onClose={handleClose}
        >
          {
            type !== 'alert'
              &&
              <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ cursor: 'move', position: 'relative'}} id="draggable-dialog-title">
                { title }
              </DialogTitle>
          }
            <DialogContent style={{ height: 300 }}>
              <div>
                { children }
              </div>
            </DialogContent>
        </Dialog>
      </div>
    )
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
})