const apiRestUrl = process.env.REACT_APP_API_REST_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

//const token = localStorage.getItem('token');

export const postEstadoTransmisionONOFF = async(estado) => {
	const url = `${ apiRestUrl }/pedido/transmisionONOFF`

	const raw = {
		"estado": estado
	};

	try {
		const resp = await fetch( url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const { mensaje_usr, mensaje_sis} = await resp.json()

            return {
				estado: mensaje_usr,
                status: mensaje_sis
			}
		} else {
			const { mensaje_usr, mensaje_sis} = await resp.json()
			return {
				status: mensaje_sis,
				message: mensaje_usr
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para cambiar el estado`
		}
	}
}

export const postEstadoTomadoAEnTransmisionONOFF = async(estado) => {
	const url = `${ apiRestUrl }/pedido/tomadoAEnTransmisionONOFF`

	const raw = {
		"estado": estado
	};

	try {
		const resp = await fetch( url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const { mensaje_usr, mensaje_sis} = await resp.json()

            return {
				estado: mensaje_usr,
                status: mensaje_sis
			}
		} else {
			const { mensaje_usr, mensaje_sis} = await resp.json()
			return {
				status: mensaje_sis,
				message: mensaje_usr
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para cambiar el estado`
		}
	}
}

export const getEstadoONOFF = async(para_dominio, para_id, para_condicion) => {
	const url = `${ apiRestUrl }/pedido/estadoONOFF`

	const raw = {
		"para_dominio": para_dominio,
    	"para_id": para_id,
    	"para_condicion": para_condicion
	};

	try {
		const resp = await fetch( url, {
			method: 'post',
			headers: myHeaders,
			body: JSON.stringify(raw),
			redirect: 'follow'
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 204) {
			const { data, respuesta} = await resp.json()

            return {
				estado: data,
                status: respuesta.mensaje_sis
			}
		} else {
			const { mensaje_usr, mensaje_sis} = await resp.json()
			return {
				status: mensaje_sis,
				message: mensaje_usr
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para cambiar el estado`
		}
	}
}