//const apiUrl = process.env.REACT_APP_API_URL
const apiLogisticaUrl = process.env.REACT_APP_API_LOGISTICA_URL
const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')

const token = localStorage.getItem('token');

export const getTiposanotAll = async() => {
	//const url = `${ apiUrl }/tiposanot`
	const url = `${ apiLogisticaUrl }/tiposanot`
	//myHeaders.set('Authorization', `Bearer ${ token }`)
    
	try {
		const resp = await fetch( url, {
			method: 'get', 
			headers: myHeaders, 
		})
		if(resp.status === 200 || resp.status === 201 || resp.status === 201) {
			const data = await resp.json()
            
			return {
				tiposanot: data.data,
				status: 'OK'
			}
		} else {
			const { errors } = await resp.json()
			return {
				status: 'NOK',
				message: errors[0].msg
			}
		}
	} catch(err) {
        return {
			status: 'NOK',
			message: `No se pudo conectar para obtener los tipos de anotaciones`
		}
	}
}
