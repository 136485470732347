import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Radio from '@material-ui/core/Radio';
import { withStyles } from '@material-ui/core/styles';
import { ButtonControl } from '../base/ButonControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'

const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));
  
export const AccionesBase = () => {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = React.useState('a')
  
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    }
    
    return (
    <div className={classes.root}>
        <Grid 
            container 
            spacing={3} 
            style={{ 
                backgroundColor: 'white',
                padding: 20
            }}
        > 
        <Grid item>
            <Radio
                checked={selectedValue === 'a'}
                onChange={handleChange}
                value="a"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
            />Atendido Contingencia
        </Grid>
        <Grid item>
            <Radio
                checked={selectedValue === 'b'}
                onChange={handleChange}
                value="b"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'B' }}
            />Anulado Contingencia
        </Grid>
        <Grid item>
            <GreenRadio
                checked={selectedValue === 'c'}
                onChange={handleChange}
                value="c"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'C' }}
            />Pendiente Contingencia
        </Grid>
        <Grid item>
            <Radio
                checked={selectedValue === 'd'}
                onChange={handleChange}
                value="d"
                color="default"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'D' }}
            />Mover a otro Móvil
        </Grid>
      </Grid>
      <Grid 
            container 
            spacing={3} 
            style={{ 
                backgroundColor: 'white',
                marginTop: 50
            }}
        > 
            <Grid item>
            <TextField
                id="camion"
                label="Camion"
                type="text"
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item style={{ paddingLeft: 200, paddingTop: 20 }}>
            <ButtonControl
                onClick={ ()=> console.log('xxx') }
                label="Guardar"
                type="secondary"
            />
        </Grid>
        </Grid>
    </div>
    )
}
