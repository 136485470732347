import React, { useState, useEffect, useContext } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { ButtonControl } from '../base/ButonControl'
import { makeStyles } from '@material-ui/core/styles'
import { getOficinasAll } from '../../helpers/moviles/apiCalls'
import { GeneralContext } from '../../context/GeneralContext' 
import { DialogAlert } from '../base/DialogAlert'
import Spinner from '../base/Spinner'
import { isEmpty } from 'lodash'
import Grid from '@material-ui/core/Grid'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '40ch',
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));


export const FormBase = ({ setReload, reload  }) => {
    const classes = useStyles();
    const { stateGeneral, setStateGeneral } = useContext(GeneralContext)
    const [message, setMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [codeAlert, setCodeAlert] = useState(false)
    const [openSpinner, setOpenSpinner] = useState(false)
    const [oficinas, setOficinas] = useState([])
    const [estados, setEstados] = useState([])

    const [values, setValues] = useState({
        clse_sector: null,
        camion: null,
        c_comuna: null,
        oficina: null,
        estado: null
    })
        
    const handleCloseAlert = () => {
        if(openAlert) { 
            setOpenAlert(false)
        }     
    }

    useEffect(() => {
        let isMounted = true

        setStateGeneral({ ...stateGeneral, filters: values })
        setOpenSpinner(true)
        getOficinasAll()
            .then(({ oficinas }) => {
                if (isMounted) {
                    setOpenSpinner(false)
                    setOficinas(oficinas)
                }
            }) 
        setEstados([{id: 1, estado: 'ACTIVO'}, {id: 0, estado: 'INACTIVO'}])
        setOpenSpinner(false)
        return () => { isMounted = false }
    },[])

    const handleChangeForm = (name, event) => {
        if(name === 'oficina' || name === 'estado') {
            setValues({ ...values, [name]: event })
        } else {
            setValues({ ...values, [name]: event.target.value })
        }
    }
    
    const onHandleSubmit = e => {
        e.preventDefault()
        setStateGeneral({ ...stateGeneral, filtersExportSll_moviles: values })
        setReload(new Date())
    }

    return (
        <div className={classes.root}>
            <form  onSubmit={ onHandleSubmit }>
            <Grid 
                container 
                spacing={3} 
                style={{ 
                    backgroundColor: 'white',
                    padding: 20
                }}
                > 
                 <Grid item xs={12}>
                    <Grid container justifycontent="center" spacing={8}>
                        
                        <Grid item>
                        {   oficinas && oficinas.length > 0 &&
                                <Autocomplete
                                    id="size-small-outlined"
                                    size="small"
                                    className= { classes.formControl  }
                                    options={oficinas}
                                    getOptionLabel={(option) => `${option.oficina} - ${option.descripcion}`}
                                    defaultValue={null}
                                    onChange={(event, newValue) => {
                                        if(!isEmpty(newValue)) {
                                            handleChangeForm("oficina", newValue.oficina)
                                        } else {
                                            handleChangeForm("oficina", null)
                                        }
                                    }}
                                    renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Oficinas" placeholder="Seleccione oficina" />
                                    )}
                                />
                                }
                        </Grid>
                
                        <Grid item>
                            {   estados && estados.length > 0 &&
                                <Autocomplete
                                    id="size-small-outlined"
                                    size="small"
                                    className= { classes.formControl  }
                                    options={estados}
                                    getOptionLabel={(option) => `${option.estado}`}
                                    defaultValue={null}
                                    onChange={(event, newValue) => {
                                        if(!isEmpty(newValue)) {
                                            handleChangeForm("estado", newValue.id)
                                        } else {
                                            handleChangeForm("estado", null)
                                        }
                                    }}
                                    renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Estados" placeholder="Seleccione estado" />
                                    )}
                                />
                            }
                        </Grid>                       

                        <Grid item style={{ marginTop: 8 }}>
                            <ButtonControl
                                label="Filtrar"
                                type="primary"
                            />
                        </Grid>
                    </Grid>
                 </Grid>
            </Grid>
            </form>
            { openAlert  &&
                    <DialogAlert 
                        title="Alerta"
                        message= { message }
                        type="alert"
                        code={ codeAlert }
                        method="DELETE"
                        openAlert={ openAlert }
                        handleCloseAlert={ handleCloseAlert }
                    />
                }
                <Spinner open={ openSpinner }/>
            </div>
    )
}


