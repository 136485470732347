import { TomaPedidosScreen } from '../components/toma-pedidos/TomaPedidosScreen'
import { GestorPedidosScreen } from '../components/gestor-pedidos/GestorPedidosScreen'
import { AdministradorScreen } from '../components/administrador/AdministradorScreen'
import { GestionSllScreen } from '../components/gestion-sll/GestionSllScreen'
import { TransmisionScreen } from '../components/transmision/Transmision'
import { DescuentosScreen } from '../components/descuentos/DescuentosScreen'
import  Login from '../components/base/Login'
import { RedirectBase } from '../components/base/RedirectBase'
import { SectorizacionScreen } from '../components/sectorizacion/Sectorizacion'
import { MovilesScreen } from '../components/moviles/Moviles'
import { TomaEmergenciasScreen } from '../components/toma-emergencias/TomaEmergencias'
import { TransmisionEmergenciasScreen } from '../components/transmision-emergencias/TransmisionEmergencias'
import { GestionSLLEmergenciasScreen } from '../components/gestion-sll-emergencias/GestionSllEmergencias'

const rutas = [
    {path: '/toma-pedidos', componente: TomaPedidosScreen, permiso: 900, text: 'Toma Pedidos' },
    {path: '/gestion-sll', componente: GestionSllScreen, permiso: 901, text: 'Gestion Sll' },
    {path: '/transmision', componente: TransmisionScreen, permiso: 902, text: 'Transmision' },
    {path: '/sectorizacion', componente: SectorizacionScreen, permiso: 903, text: 'Sectorizacion' },
    {path: '/moviles', componente: MovilesScreen, permiso: 904, text: 'Moviles' },
    {path: '/descuentos', componente: DescuentosScreen, permiso: 905, text: 'Descuentos' }, 
    //{path: '/gestor-pedidos', componente: GestorPedidosScreen, isAdmin: "administrador" }, 
    {path: '/administrador', componente: AdministradorScreen, permiso: 906, text: 'Administrador' },

    {path: '/toma-emergencias', componente: TomaEmergenciasScreen, permiso: 909, text: 'Toma Emergencias' },
    {path: '/transmision-emergencias', componente: TransmisionEmergenciasScreen, permiso: 910, text: 'Transmision Emergencias' },
    {path: '/gestion-sll-emergencias', componente: GestionSLLEmergenciasScreen, permiso: 911, text: 'Gestion Sll - Emergencias' },

    {path: '/login', componente: Login },
    {path: '', componente: Login },
    //{path: '*', componente: RedirectBase }
]

export default rutas